import {
    TextField,
    IndexTable,
    TextStyle,
    Card,
    Filters,
    Select,
    useIndexResourceState,
    Pagination,
    Stack,
  } from '@shopify/polaris';
  import {useState, useCallback, useRef, useEffect} from 'react';
import Api from '../Services/CommonServices';
const api = new Api();
  export function JsonLogs() {
    const shopOrigin = useRef('');
    let [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [nxtButton, setNxtButton] = useState(true);
    const [prvButton, setPrvButton] = useState(false);
    const [nxtPage, setNxtPage] = useState(true);
    const [prvPage, setPrvPage] = useState(false);
    const [shopTimeZone, setShopTimeZone] = useState("");
    const [logs, setLogs] = useState([]);
    const [logsCount, setLogsCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [queryValue, setQueryValue] = useState(null);
    const handleQueryValueRemove = useCallback(() => { setQueryValue(null); getInventoryLogs(pageNo,null) }, []);
  useEffect(() => {
    shopOrigin.current = api.QueryStringToJSON();
    getStore();
    getInventoryLogs(pageNo,queryValue)
  }, []);

    const resourceName = {
      singular: 'log',
      plural: 'logs',
    };

  
  
    const filters = [];
  
    const rowMarkup = logs.map(
      ({date, productId, sku, inventoryQtyOld, inventoryQtyNew,priceOld,priceNew,compareAtPriceNew,compareAtPriceOld}, index) => (
        <IndexTable.Row
          id={productId+index}
          key={productId+index}
          position={index}>
          <IndexTable.Cell>{date}</IndexTable.Cell>
          <IndexTable.Cell>{sku}</IndexTable.Cell>
          <IndexTable.Cell><TextStyle variation="negative">{inventoryQtyOld}</TextStyle>&nbsp;&#8594;&nbsp;<TextStyle variation="positive">{inventoryQtyNew}</TextStyle></IndexTable.Cell>
          <IndexTable.Cell><TextStyle variation="negative">{priceOld}</TextStyle>&nbsp;&#8594;&nbsp;<TextStyle variation="positive">{priceNew}</TextStyle></IndexTable.Cell>
          <IndexTable.Cell><TextStyle variation="negative">{compareAtPriceOld}</TextStyle>&nbsp;&#8594;&nbsp;<TextStyle variation="positive">{compareAtPriceNew}</TextStyle></IndexTable.Cell>
        </IndexTable.Row>
      ),
    );
  
    return (
      <Card>
        <div style={{padding: '16px', display: 'flex'}}>
          <div style={{flex: 1}}>
            <Filters
              queryValue={queryValue}
              filters={filters}
              onQueryChange={e => {setQueryValue(e);getInventoryLogs(pageNo,e)}}
              onQueryClear={handleQueryValueRemove}
            />
          </div>
        </div>
        <IndexTable
          resourceName={resourceName}
          itemCount={logs.length}
          headings={[
            {title: 'Date'},
            {title: 'Sku'},
            {title: 'Qty'},
            {title: 'Price'},
            {title: 'Compare at price'}
          ]}
          selectable={false}
        >
          {rowMarkup}
        </IndexTable><br/>
        {pageCount > 1 ?
        <Stack alignment="center" vertical={true}>
        <Pagination
      label={logsCount}
      hasPrevious={prvButton}
      onPrevious={() => {
        prev();
      }}
      hasNext={nxtButton}
      onNext={() => {
        next();
      }}
    />
    </Stack> : undefined }
    
    <br/>
      </Card>
    );

    function getStore() {
        api.getStore(shopOrigin.current.shop)
            .then(data => {
                setShopTimeZone(data.iana_TimeZone)
            });
    }

    function next() {
        pageNo = pageNo + 1
        if (pageCount >= pageNo) {
          setPageNo(pageNo);
          getInventoryLogs(pageNo,queryValue);
          setPrvButton(true);
        }
        else { setNxtButton(false); setPrvButton(true); }
      }
    
      function prev() {
        pageNo = pageNo - 1
        if (pageNo >= 1) {
          setPageNo(pageNo);
          getInventoryLogs(pageNo,queryValue);
          setNxtButton(true)
        }
        else { setPrvButton(false); setNxtButton(true) }
      }


    function getInventoryLogs(pageno,queryvalue)
    {
        var data = [];
        var searchFor = queryvalue === "" || queryvalue === null ? 'null' : queryvalue;
        api.getInventoryLogs(shopOrigin.current.shop,pageno,pageSize,searchFor,"Json")
        .then(res => {
            setLogsCount(res.data.itemsCount);
            setPageCount(res.data.itemsCount/pageSize);
            res.data.data.forEach(log => {
                console.log(convertTZ(log.updatedAt, "America/Newyork"))
                var log = {
                    date: convertTZ(log.updatedAt, "America/Newyork"),
                    productId: log.productId,
                    sku: log.sku,
                    inventoryQtyNew: log.qtyNew,
                    inventoryQtyOld: log.qtyOld,
                    priceNew: log.priceNew,
                    priceOld: log.priceOld,
                    compareAtPriceNew: log.compareAtPriceNew,
                    compareAtPriceOld: log.compareAtPriceOld
                }
                data.push(log);
            });
            setLogs(data);
            
        });
    }

    function convertTZ(date, tzString) {
        return new Date(date).toLocaleString('en-US' /*, o*/);   
    }

  }