import React, { useCallback, useState, useEffect, useRef } from 'react';
import { AccountConnection, Card, Form, Layout, Page, SettingToggle, Tabs, TextStyle } from '@shopify/polaris';
import useLoader from '../Loader/UseLoader';
import useSuccessToast from '../Toast/UseSuccessToast';
import useErrorToast from '../Toast/UseErrorToast';
import Api from '../Services/CommonServices';
import { environment } from '../environments/environment.prod';
import { QboConfig } from './QboConfig';
import { QboSalesOrder } from './QboSalesOrder';
import { Setting } from '../Setting';
const api = new Api();
const endpoint = environment.serverUrl;
export function QboSetting() {
  const [active, setActive] = useState(false);
  const [goBackActive, setGoBackActive] = useState(false);
  const [loader, showLoader, hideLoader] = useLoader();
  const [toastMarkup, showToast] = useSuccessToast();
  const [toastErrorMarkup, showErrorToast] = useErrorToast();
  const handleToggle = useCallback(() => setActive((active) => !active), []);
  const contentStatus = active ? 'Disable' : 'Enable';
  const textStatus = active ? 'enabled' : 'disabled';
  const [companyName, setCompanyName] = useState('');
  const [connected, setConnected] = useState(false);
  const accountName = connected ? companyName : '';
  const shopOrigin = useRef('');
  const handleAction = useCallback(() => {
    setConnected((connected) => !connected);
  }, [connected]);

  const buttonText = connected ? 'Disconnect' : 'Connect';
  const details = connected ? companyName + ' connected' : 'No account connected';
  const terms = connected ? null : (
    <p>
      By clicking <strong>Connect</strong>, Tallify Sync will connect with Quickbooks.
    </p>
  );



  useEffect(() => {
    shopOrigin.current = api.QueryStringToJSON();
    getQboConfigs();
    QboAppData();
  }, []);

  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    [],
  );



  function QboAppData() {
    showLoader();
    api.QboAppData(shopOrigin.current.shop).then(response => {
      response.companyName != undefined ? setConnected(true) : setConnected(false)
      setCompanyName(response.companyName);
    })
    hideLoader();
  }

  function QboAuthorization() {
    showLoader();
    api.QboAuthorization(shopOrigin.current.shop).then(response => {
      window.open(response, '_top');
      //console.log("auth url=", response);
    })
    hideLoader();
  }
  function DeleteQboAuthorization() {
    showLoader();
    api.DeleteQboAuthorization(shopOrigin.current.shop).then(response => {
      //console.log("Deleted=", response);
      setConnected(false)
    })
    hideLoader();
  }
  const handleFormToggle = (evt) => {
    evt.preventDefault();
    updateQboStatus();
  }
  const handleQboAuthorize = (evt) => {
    evt.preventDefault();
    //console.log("clicked")
    connected ? QboAuthorization() : DeleteQboAuthorization();
  }

  async function getQboConfigs() {
    showLoader();
    api.getQboConfigs(shopOrigin.current.shop)
      .then(items => {
        if(items != null)
        {items.qboStatus === "Disable" ? setActive(false) : setActive(true)}
        hideLoader();
      });
  }

  async function updateQboStatus() {
    var storeStatus = contentStatus === "Disable" ? "Enable" : "Disable";
    //console.log(shopOrigin.current.shop)
    const data = {
      Shop: shopOrigin.current.shop,
      QboStatus: storeStatus
    };
    //console.log("post data=", data);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    fetch(endpoint + '/api/QBOConfig/Qbo_status', requestOptions)
      .then(function (response) {
        if (response.status === 200 && response != null) {
          showToast();
          return response
        } else {
          showErrorToast();
          throw new Error('Empty data')
        }
      })
      .catch(function (error) {
        //console.log(error)
        return []
      })
  }



  const qboConfig = (<><Layout>
    <Layout.AnnotatedSection
      title="General"
      description="Customize your app settings.">
      <Card>
        <Form onSubmit={handleFormToggle}>
          <SettingToggle
            action={{
              content: contentStatus,
              onAction: handleToggle,
              submit: true
            }}
            enabled={active}>
            Quickbooks is <TextStyle variation="strong">{textStatus}</TextStyle>.
          </SettingToggle>
        </Form>
      </Card>
      <Card></Card>
      <Card>
        <Form onSubmit={handleQboAuthorize}>
          <AccountConnection
            accountName={accountName}
            connected={connected}
            title="Authorize Quickbooks"
            action={{
              content: buttonText,
              onAction: handleAction,
              submit: true
            }}
            details={details}
            termsOfService={terms}
          />
        </Form>
      </Card>
    </Layout.AnnotatedSection>
  </Layout>
    <Layout>
      <Layout.AnnotatedSection>
        <Card></Card>
      </Layout.AnnotatedSection>
    </Layout>
    <Layout>
      <Layout.AnnotatedSection
        title="Select account and customer"
        description="Sales account needed to sync sales orders to customers.Default customer needed to assign customer to sales without customer">
        <QboConfig />
      </Layout.AnnotatedSection>

      {loader}
    </Layout></>
  );

  const salesOrder = (
    <Layout>
      <Layout.AnnotatedSection
        title="Sales order"
        description="Click on create invoice to sync the order to quickbooks">
        <Card sectioned>
          <QboSalesOrder />
        </Card>
      </Layout.AnnotatedSection>
      {loader}
    </Layout>
  );

  const tabs = [
    {
      id: 'qbo-config',
      content: 'Config',
      componentRender: qboConfig,
    },
    {
      id: 'sales-order',
      content: 'Sales Order',
      componentRender: salesOrder,
    }
  ];

  return (
    <>
    {goBackActive == false ? 
    <Page title='Quickbooks Setup' breadcrumbs={[{ content: 'Settings', onAction: goBack }]}>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        <Card.Section title={tabs[selected].componentRender}>
        </Card.Section>
      </Tabs>
      {toastMarkup}
      {toastErrorMarkup}
    </Page>
    : <Setting/>}
    </>
  );

  function goBack()
  {
    setGoBackActive(true);
  }
}