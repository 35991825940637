import React, { useState, useCallback } from 'react';
import { Frame, Page, Toast } from '@shopify/polaris';
const useErrorToast =() => {
    const [active, setActive] = useState(false);
    const showErrorToast = useCallback(() => setActive(active => !active), []);
    const toastErrorMarkup = active ? (
    <div style={{ height: '250px' }}>
    <Frame>
      <Page><Toast content="Something went wrong. Please try again later"  onDismiss={showErrorToast}  duration={3000} error={true} />
      </Page>
      </Frame>
    </div>) : null;
    
return[toastErrorMarkup,showErrorToast];
}
export default useErrorToast;