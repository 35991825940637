import React from 'react';
import { Spinner } from '@shopify/polaris';

const Loader =() => {
    const style = { position: "fixed", top: "60%", left: "60%", transform: "translate(-50%, -50%)" };
    return(          
        <div style={style}>
        <Spinner accessibilityLabel="Spinner example" size="large" color="teal" />
        </div>
    );
}
export default Loader;