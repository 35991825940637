import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Card, FormLayout, TextField, PageActions, Form, Select, Button, AppProvider, TextStyle, ChoiceList, Subheading, Layout, Page, SettingToggle, RadioButton, Stack, Icon, Banner, SkeletonPage, SkeletonDisplayText, SkeletonBodyText, TextContainer, Modal, List, EmptyState, Checkbox } from '@shopify/polaris';
import useLoader from '../Loader/UseLoader';
import Api from '../Services/CommonServices';
import useSuccessToast from '../Toast/UseSuccessToast';
import useErrorToast from '../Toast/UseErrorToast';
import { environment } from '../environments/environment.prod';
import * as signalR from "@microsoft/signalr";
import { KeyMajor, RefreshMinor } from '@shopify/polaris-icons';
const api = new Api();
const endpoint = environment.serverUrl;
export function TallyConfig(props) {

    // variables
    const { enabled, isEnabled } = props;
    const [loader, showLoader, hideLoader] = useLoader();
    const [toastMarkup, showToast] = useSuccessToast();
    const [toastErrorMarkup, showErrorToast] = useErrorToast()
    const [companySaved, setCompanySaved] = useState("");
    const [isGodownSelected, setIsGodownSelected] = useState(true);
    const [configMismatch, setConfigMismatch] = useState(false);
    const [selectedTallyCompany, setSelectedTallyComapany] = useState("");
    const [tallyPortSaved, setTallyPortSaved] = useState("9000");
    const [tallyPortChanged, setTallyPortChanged] = useState("");
    const [tallyIpSaved, setTallyIpSaved] = useState("localhost");
    const [tallyIpChanged, setTallyIpChanged] = useState("");
    const [godownsSaved, setGodownsSaved] = useState([]);
    const [currentGodownsSelected, setCurrentGodownsSelected] = useState([]);
    const [currentGodowns, setCurrentGodowns] = useState([]);
    const [frequencySaved, setFrequencySaved] = useState(0);
    const [frequencyChanged, setFrequencyChanged] = useState(5);
    const [active, setActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleToggle = useCallback(() => setActive((active) => !active), []);
    const contentStatus = active ? 'Disable' : 'Enable';
    const textStatus = active ? 'enabled' : 'disabled';
    const [apiKey, setApiKey] = useState('');
    const [systemInfo, setSystemInfo] = useState('');
    const [skuField, setSkuField] = useState('AliasOnly');
    const [isConsoleConnected, setIsConsoleConnected] = useState(false);
    const [isTallyRunning, setIsTallyRunning] = useState(false);
    const [verifyBtnLoad, setVerifyBtnLoad] = useState(false);
    const [saveBtnLoad, setSaveBtnLoad] = useState(false);
    const [modalActive, setModalActive] = useState(false);
    const handleFrequencyChange = useCallback(newValue => setFrequencyChanged(newValue), []);
    const handleModalChange = useCallback(() => { setModalActive((modalActive) => !modalActive) }, []);
    const handleGodownCheckChange = useCallback((newChecked) => {  setCurrentGodownsSelected(newChecked); setIsGodownSelected(true); }, []);
    const handleSkuFieldChange = useCallback((value) => setSkuField(value), []);
    const handleTallyIpChange = useCallback(newValue => { if (newValue !== tallyIpChanged) { setIsIpChanged(true); setTallyIpChanged(newValue) } else { setTallyIpChanged(newValue); } }, []);
    const handleTallyPortChange = useCallback(newValue => { if (newValue !== tallyPortChanged) { setIsIpChanged(true); setTallyPortChanged(newValue) } else { setTallyPortChanged(newValue); } }, []);
    const [isIpChanged, setIsIpChanged] = useState(false);
    const [isPortChanged, setIsPortChanged] = useState(false);
    const [tallyStatus, setTallyStatus] = useState(false);
    const handleReconfigure = useCallback((newChecked) => { setCompanySaved(selectedTallyCompany) }, []);
    const [priceChecked, setPriceChecked] = useState(false);
    const handlePriceChange = useCallback((newChecked) => { setPriceChecked(newChecked);setIsFormValid(true); }, []);
    const [compareAtPriceChecked, setCompareAtPriceChecked] = useState(false);
    const handleCompareAtPriceChange = useCallback((newChecked) => { setCompareAtPriceChecked(newChecked);setIsFormValid(true); }, []);
    const [stockChecked, setStockChecked] = useState(false);
    const [isFormValid, setIsFormValid] = useState(true);
    const [isLocationSelected, setIsLocationSelected] = useState(true);
    const handleStockChange = useCallback((newChecked) => { setStockChecked(newChecked); setIsFormValid(true); }, []);
    const [selectedLocation, setSelectedLocation] = useState("");
    const [locationCount, setLocationCount] = useState(1);
    const [locations, setLocations] = useState([]);
    const handleSelectChange = useCallback((value) => { setSelectedLocation(value); setIsLocationSelected(true)}, []);
    const shopOrigin = useRef('');

    const skuFieldOptions = [
        { label: 'Alias Only', value: 'AliasOnly' },
        { label: 'Name Only', value: 'NameOnly' },
        { label: 'PartNo Only', value: 'PartNoOnly' },
    ];

    const frequencyOptions = [
        { label: '5', value: '5' },
        { label: '10', value: '10' },
        { label: '15', value: '15' },
        { label: '45', value: '45' },
        { label: '60', value: '60' },
    ];
    // handle state change

    useEffect(() => {
        shopOrigin.current = api.QueryStringToJSON();
        getStore();
        getLocations();
    }, []);

    const handleFormSubmit = (evt) => {
        evt.preventDefault();
        addTallyConfigs();
    }




    const handleVerifyPort = (evt) => {
        setVerifyBtnLoad(true);
        evt.preventDefault(tallyIpChanged, tallyPortChanged);
        api.tallyStatus(shopOrigin.current.shop, tallyIpChanged, tallyPortChanged);
    }

    function verifyPort(tallyip, tallyPort) {
        api.tallyStatus(shopOrigin.current.shop, tallyip, tallyPort);
    }

    const handleFormToggle = (evt) => {
        evt.preventDefault();
        // addTallyConfigs("status");
        tallyonfigStatus("status");
    }




    // html 
    const apiKeyConfirmationModalHtml = (
        <div style={{ height: '500px' }}>
            <Modal
                open={modalActive}
                onClose={handleModalChange}
                title="Are you sure you want to generate new API key ?"
                primaryAction={{
                    destructive: true,
                    content: 'Generate new api key',
                    onAction: GenerateApiKey,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: handleModalChange,
                    },
                ]}>
                <Modal.Section>
                    <TextContainer>
                        <p>
                            This will remove all the connections between client and server.<br />
                            This action cannot be reversed.
                        </p>
                    </TextContainer>
                </Modal.Section>
            </Modal>
        </div>
    );


    const inventorySyncHtml = (
        <>
            <Layout>
                <Layout.AnnotatedSection>
                    <Card sectioned>
                        <Stack wrap={false} alignment="leading" spacing="loose">
                            <Stack.Item fill>
                                <FormLayout>
                                    <FormLayout.Group condensed>
                                        <TextField helpText="Please click on refresh to update the company name after tally company change"
                                            label="Selected company name in tally"
                                            type="text" value={selectedTallyCompany} readOnly={true} />
                                    </FormLayout.Group>
                                </FormLayout>
                                <div style={{ marginTop: "4px" }}>
                                </div>
                            </Stack.Item>
                            <div style={{ paddingTop: "24px" }}>
                                <Button primary onClick={() => { getStoreDetails(); getTallyData(selectedTallyCompany, tallyIpChanged, tallyPortChanged) }} icon={RefreshMinor} accessibilityLabel="Refresh" />
                            </div>
                        </Stack>
                    </Card>
                </Layout.AnnotatedSection></Layout>
            <Layout>
                <Layout.AnnotatedSection>
                    <Card></Card>
                </Layout.AnnotatedSection>
            </Layout>
            <Layout>
                <Layout.AnnotatedSection
                    title="Inventory sync"
                    description="Customize your app settings">
                    <Card sectioned>
                    <TextStyle>Select the fields to sync</TextStyle>
                        <FormLayout>
                        <Checkbox
                                        label="Price"
                                        checked={priceChecked}
                                        onChange={handlePriceChange}
                                    />
                                    {/* <Checkbox
                                        label="Compare at price"
                                        checked={compareAtPriceChecked}
                                        onChange={handleCompareAtPriceChange}
                                    /> */}
                                    <Checkbox
                                        label="Stock"
                                        checked={stockChecked}
                                        onChange={handleStockChange}
                                    />
                                    {!isFormValid ? 
                                    <TextStyle variation="negative">Select at least one option</TextStyle> : undefined }
                                    {locationCount > 1 ?
                                        <Select
                                            label="Location"
                                            options={locations}
                                            onChange={handleSelectChange}
                                            helpText="Select location to sync inventory"
                                            error = {!isLocationSelected ? "Select at least one option" : false}
                                            value={selectedLocation}
                                        /> : undefined }
                            <FormLayout.Group>
                                <>
                                    <Select
                                        label="Select tally sync frequency(mins)"
                                        options={frequencyOptions}
                                        onChange={handleFrequencyChange}
                                        value={frequencyChanged}
                                        disabled={false}
                                        helpText="Set frequency for auto sync in minutes"
                                    /><br />
                                </>
                                <>
                                    <Select
                                        label="Sku field in item"
                                        helpText="Location of an sku in a tally stock item(case sensitive)"
                                        options={skuFieldOptions}
                                        onChange={handleSkuFieldChange}
                                        value={skuField}
                                    /> <br /></>
                            </FormLayout.Group>
                        </FormLayout>
                        <div><Subheading>Tally Godowns</Subheading><br></br>
                            <ChoiceList
                                allowMultiple
                                title="Select the godowns"
                                error={isGodownSelected ? undefined : "Select atleast 1 godown"}
                                choices={currentGodowns}
                                selected={currentGodownsSelected}
                                onChange={handleGodownCheckChange}
                            />
                        </div>
                    </Card>
                </Layout.AnnotatedSection>
            </Layout>

            <Layout>
                <Layout.AnnotatedSection>
                    <Card></Card>
                    <PageActions
                        primaryAction={{
                            content: 'Save',
                            submit: true,
                            loading: saveBtnLoad,
                            disabled: !isConsoleConnected && tallyStatus
                        }} />
                </Layout.AnnotatedSection>
            </Layout>
            {toastMarkup}
            {toastErrorMarkup}
        </>
    );

    const tallyPortHtml = (
        <FormLayout>
            <FormLayout.Group condensed>
                <TextField label="Enter the tally-ip" type="text" placeholder="localhost" helpText="tally-ip (eg:localhost)" id="tally-ip" value={tallyIpChanged} onChange={handleTallyIpChange} />
                <TextField label="Enter the tally-port" type="number" placeholder="9000" helpText="tally-port (eg:9000)" id="tally-port" value={tallyPortChanged} onChange={handleTallyPortChange} />
                <div style={{ paddingTop: "24px" }}>
                    <Button
                        // disabled={(tallyIpChanged === '' || tallyPortChanged === '') || isTallyRunning == false ? true : false}
                        primary={true}
                        loading={verifyBtnLoad}
                        fullWidth={true}
                        onClick={handleVerifyPort}>
                        Verify
                </Button>
                </div>
            </FormLayout.Group>
            {connectedSystemAlert()}
            {tallyPortAlert()}
            {tallyErpErrorAlert()}
        </FormLayout>
    );

    function tallyPortAlert() {
        // <>
        if (tallyStatus && !isIpChanged && !isPortChanged && isConsoleConnected)
            return (<Banner
                title={<TextStyle variation="positive">Tally is running on port number: {tallyPortChanged}</TextStyle>}
                status="success">
            </Banner>);
        else if (!isIpChanged && !isPortChanged && isConsoleConnected)
            return (<Banner
                title={<TextStyle variation="negative">Tally is not running on port number: {tallyPortChanged}</TextStyle>}
                status="critical">
            </Banner>);
    }

    function connectedSystemAlert() {
        if (isConsoleConnected)
            return (<Banner
                title={<TextStyle variation="positive">Connected system: {systemInfo}</TextStyle>}
                status="success">
            </Banner>)
        else
            return (
                <Banner
                    title={<TextStyle variation="negative">Client app disconnected.Try again later.</TextStyle>}
                    status="critical">
                </Banner>)
    }

    function tallyErpErrorAlert() {
        if (!isTallyRunning)
            return (<Banner
                title="No connection could be made. Tally ERP is not runnning"
                status="critical">
            </Banner>)
    }
    // function reConfigure(selectedCompany) {
    //     setCompanySaved(selectedCompany);
    //     setActive(false);tallyonfigStatus();
    // }

    const companyMismatchWarning = (
        <Layout>
            <Layout.AnnotatedSection>
                <Card sectioned>
                    <Banner
                        title={'"' + companySaved + '"' + " has been configured as the company to sync but " + '"' + selectedTallyCompany + '"' + " is currently open on Tally. To fix this:"}
                        action={{ content: 'Yes, reconfigure', onAction: () => { setCompanySaved(selectedTallyCompany); setActive(false);tallyonfigStatus("reconfigure") } }}
                        secondaryAction={{ content: 'Refresh', onAction: () => { getTallyData(selectedTallyCompany, tallyIpChanged, tallyPortChanged) } }}
                        status="warning"
                    >
                        <List>
                            <List.Item>
                                Open {companySaved} in Tally or you can reconfigure to sync {selectedTallyCompany}
                            </List.Item>
                        </List>
                    </Banner>
                </Card>
            </Layout.AnnotatedSection>
        </Layout>
    );

   

    var ifConfigChanged = (selectedTallyCompany !== "" && companySaved !== "" ) ? (selectedTallyCompany !== companySaved ? true : false) : false;
    var disabledBanner = (
        <>
        
         {ifConfigChanged ? companyMismatchWarning : <Layout>
            <Layout.AnnotatedSection>
                <EmptyState
                    heading="Tallify integration is disabled">
                    <p>Enabling Tallify will take you to configuration .</p>
                </EmptyState>
            </Layout.AnnotatedSection>
        </Layout>}
         </>
    );
    return (
        <>

            {loader}

            <Layout>
                <Layout.AnnotatedSection>
                    <Card>
                        <Form onSubmit={handleFormToggle}>
                            <SettingToggle
                                action={{
                                    content: contentStatus,
                                    onAction: handleToggle,
                                    submit: true
                                }}
                                enabled={active}>
                                Tallify is <TextStyle variation="strong">{textStatus}</TextStyle>.
                                    </SettingToggle>
                        </Form>
                    </Card>
                    <Card></Card>
                </Layout.AnnotatedSection>
            </Layout>
           
            { active ?
                <Form onSubmit={handleFormSubmit}>
                    <Layout>
                        <Layout.AnnotatedSection
                            title="Tally Config"
                            description="Customize your app settings.">
                            <Card sectioned>
                                <Stack alignment="leading">
                                    <Stack.Item fill>
                                        <TextField helpText="Use this API key to setup the tallify console app" label="API key" type="text" value={apiKey} readOnly={true} />
                                    </Stack.Item>
                                    <div style={{ paddingTop: "23px" }}>
                                        {/* <Button plain onClick={handleModalChange}>generate key</Button> */}
                                        <Button primary onClick={handleModalChange} icon={KeyMajor} accessibilityLabel="Refresh" />
                                    </div>
                                </Stack>
                                <TextStyle variation="subdued">Install a service:  TallySync.exe install</TextStyle>
                                <br /><TextStyle variation="subdued">Start a service:  TallySync.exe start</TextStyle>
                                <br /><TextStyle variation="subdued">Uninstall a service:  TallySync.exe uninstall</TextStyle>
                                <br /><TextStyle variation="subdued">Stop a service:  TallySync.exe stop</TextStyle>
                                <br /><br />
                                {tallyPortHtml}
                            </Card>
                        </Layout.AnnotatedSection></Layout><br />
                    {isConsoleConnected && tallyStatus && !isIpChanged  ? inventorySyncHtml : undefined}
                    {/* {ifConfigChanged ? companyMismatchWarning : undefined} */}
                </Form>
                : disabledBanner }
            { modalActive ? apiKeyConfirmationModalHtml : undefined}
        </>
    );


    //// functions ////



    function getTallyConfig(tallyData) {
        //console.log("getTallyConfig=", tallyData);
        hideLoader();
        if (tallyData.length > 0) {
            var data = tallyData[0];
            //console.log("getTallyConfig status=", data.tallifyStatus);
            setIsLoading(true);
            if (data.length !== 0) {
                setActive(data.tallifyStatus === 0 ? true : false);
                isEnabled();
                data.companyName !== null ? setCompanySaved('' + data.companyName) : setCompanySaved(companySaved);
                setTallyIpSaved('' + data.tallyIP);
                setTallyPortSaved('' + data.tallyPort);
                setTallyIpChanged('' + data.tallyIP);
                setTallyPortChanged('' + data.tallyPort);
                setFrequencySaved('' + data.frequency);
                data.godowns!== null ? setGodownsSaved(data.godowns) : setGodownsSaved(godownsSaved);
                data.godowns!== null ? setCurrentGodownsSelected(data.godowns) : setCurrentGodownsSelected(currentGodownsSelected);
                setPriceChecked(data.priceSync);
                setStockChecked(data.stockSync);
                setCompareAtPriceChecked(data.compareAtPriceSync);
                setSelectedLocation(""+data.locationid);
                // setSelectedSalesLedger('' + data.salesLedger)
                // setSelectedPartyLedger('' + data.partyLedger)
                // data.ledgerStatus !== "" ? setValue('' + data.ledgerStatus) : setValue('fixed');
                // data.godowns != null ? setSelectedGodowns(data.godowns) : setSelectedGodowns("");
                if (data.companyName !== "" || data.tallyIP !== "" || data.tallyPort !== "") {


                    api.tallyConnectionStatus(shopOrigin.current.shop).then(response => {
                        //console.log("tally status=", response.data);
                        setIsConsoleConnected(response.data.connectionStatus);
                        setSystemInfo(response.data.connectedSystem);
                        // setApiKey(response.data.apiKey);
                        if (response.data.connectionStatus) {
                            getTallyData(data.companyName, data.tallyIP, data.tallyPort);
                        }
                    })

                }
                return true;
            }
        } else { return false; }
    }

     function SignalRConnectionManagement() {
        const hubConnection = new signalR.HubConnectionBuilder().withUrl(environment.serverUrl + "/tally-hub").configureLogging(signalR.LogLevel.Information).build();

        hubConnection.start().then(a => { 
            if (hubConnection.connectionId) 
            { 
                hubConnection.invoke("OnConnectedFrontEnd", hubConnection.connectionId, shopOrigin.current.shop); 
            } }).catch(function (error) { });

        hubConnection.onclose(() => { showErrorToast(); })

        hubConnection.on("reactConnected", message => { 
            console.log("react connected message=",message); 
            api.getStore(shopOrigin.current.shop)
            .then(data => {
                setApiKey(data.tallyApiKey);
                // var res = getTallyConfig(data.tallyConfigs);
            getTallyConfig(data.tallyConfigs); });
        });
        //sales ledger response
        // hubConnection.on("salesLedger", message => {setSalesLedger([]);const salesLedgerOptions = []; message.forEach(per => {const salesledger = {value: per.salesLedgerName, label: per.salesLedgerName};salesLedgerOptions.push(salesledger)});setSalesLedger(salesLedgerOptions);});

        //partyLedger response
        // hubConnection.on("partyLedger", message => {setPartyLedger([]);const partyLedgerOptions = [];message.forEach(per => {const ledger = {value: per.partyLedgerName, label: per.partyLedgerName};partyLedgerOptions.push(ledger)});setPartyLedger(partyLedgerOptions);});
        //ledgerList response
        // hubConnection.on("ledgerList", message => {setLedgerList([]);const ledgerListOptions = [];message.forEach(per => {const ledger = {value: per.ledgerName, label: per.ledgerName};ledgerListOptions.push(ledger)});setLedgerList(ledgerListOptions);});

        hubConnection.on("godownList", message => {
            // console.log("godownList=", message);
            setCurrentGodowns(message);
            hideLoader()
        });

        hubConnection.on("tallyCompanies", message => {
            // console.log("setSelectedTallyComapany=", message);
            setSelectedTallyComapany(message);
        });

        // hubConnection.on("voucherTypes", message => {
        //     setVoucherTypes([]);
        //     const voucherTypeOptions = []
        //     message.forEach(com => {
        //         const data = {
        //             value: com.voucherTypeName, label: com.voucherTypeName
        //         };
        //         voucherTypeOptions.push(data);
        //     })
        //     setVoucherTypes(voucherTypeOptions);
        // });

        hubConnection.on("tallyStatus", response => {
            setIsIpChanged(false); setIsPortChanged(false);
            setVerifyBtnLoad(false);
            setTallyStatus(response);
            // setTallyStatus(response);
        });

        hubConnection.on("ConsoleconnectionDIsconnect", response => {
            //console.log("ConsoleconnectionDIsconnect called", response);
            setIsConsoleConnected(!response);
            // setConsoleDisConnected(response); if (!response) { getStore(); }
            // setTallyConnected(!response);
        });

        hubConnection.on("TallyErpStatus", response => {
            setIsTallyRunning(response);
            hideLoader();
        });
        hubConnection.on("companyMismatch", response => {
            // console.log("company mismatch=",response);
            if (response) { setCurrentGodownsSelected([]); }
        });


    }

     function getStore() {
        showLoader();
        api.getStore(shopOrigin.current.shop)
            .then(data => {
                setApiKey(data.tallyApiKey);
                // var res = getTallyConfig(data.tallyConfigs);
                // if (res) {
                    SignalRConnectionManagement();
                // }

            });
    }

    function getLocations() {
        var locationList= [];
        api.getLocations(shopOrigin.current.shop)
            .then(data => {
                setLocationCount(data.length);
                console.log("locations=",data.length,data);
                data.forEach(locationRes => {
                    var location = {
                        value: locationRes.admin_graphql_api_id,
                        label: locationRes.name
                    }
                    locationList.push(location);
                });
                setLocations(locationList);
                console.log("location list=",locationList);
            });
    }


    function getStoreDetails() {
        showLoader();
        api.getStore(shopOrigin.current.shop)
            .then(data => {
                setApiKey(data.tallyApiKey);
                getTallyConfig(data.tallyConfigs);

            });
    }

     function getTallyData(companyName, tallyIp, tallyPort) {
        // showLoader();
        // setCompanyName([]);
        // setSalesLedger([]);
        // setPartyLedger([]);
        // setGodowns([]);
        api.getTallyResponse(shopOrigin.current.shop, "CurrentCompany", companyName, tallyIp, tallyPort).then(response => {
            // api.getTallyResponse(shopOrigin.current.shop, "PartyLedger", companyName, tallyIp, tallyPort)
            // api.getTallyResponse(shopOrigin.current.shop, "SalesLedger", companyName, tallyIp, tallyPort)
            api.getTallyResponse(shopOrigin.current.shop, "GodownList", companyName, tallyIp, tallyPort)
            // api.getTallyResponse(shopOrigin.current.shop, "VoucherType", companyName, tallyIp, tallyPort)
        });
    }
    function tallyonfigStatus(operation) {
        showLoader();
        var status =  operation !== "reconfigure" ? (active ? "Enabled" : "Disabled") : "Enabled"
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch(endpoint + '/api/TallyConfig/tally-config-status?shopUrl=' + shopOrigin.current.shop + "&status=" + status, requestOptions)
            .then(function (response) {
                if (response.status === 200 && response != null) {
                    showToast();
                    hideLoader();
                    getStoreDetails();
                    isEnabled();
                    
                    return response
                } else {
                    showErrorToast();
                    hideLoader();
                    throw new Error('Empty data')
                }
            })
            .catch(function (error) {
                hideLoader();
                return []
            })
    }

     function addTallyConfigs() {
        setSaveBtnLoad(true);
        //console.log("Add tally config called");
        const data = {
            companyName: selectedTallyCompany,
            frequencyChanged: frequencyChanged,
            godowns: currentGodownsSelected,
            tallyIp: tallyIpChanged === "" ? tallyIpSaved : tallyIpChanged,
            tallyPort: tallyPortChanged === "" ? tallyPortSaved : tallyPortChanged,
            skuField: skuField,
            priceSync: priceChecked,
            stockSync: stockChecked,
            compareAtPriceSync: false,
            locationId: selectedLocation == "" ? locations[0].admin_graphql_api_id : selectedLocation
        }
        console.log("Current godown=",currentGodownsSelected)
        var isInventorySyncFieldsSelected = false;
        if(priceChecked) { isInventorySyncFieldsSelected = true; }  else if(stockChecked) { isInventorySyncFieldsSelected = true; }
        if (currentGodownsSelected.length === 0 ) { setIsGodownSelected(false); setSaveBtnLoad(false); }
        else if (selectedLocation =="" ) { setIsLocationSelected(false); setSaveBtnLoad(false); }
        else if (!isInventorySyncFieldsSelected) { setIsFormValid(false); setSaveBtnLoad(false); }
        else {
            setIsGodownSelected(true);
            setIsFormValid(true);
            setIsLocationSelected(true);
            //console.log("save config data else =", data);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            };
            fetch(endpoint + '/api/TallyConfig/add?shopUrl=' + shopOrigin.current.shop, requestOptions)
                .then(function (response) {
                    if (response.status === 200 && response != null) {
                        showToast();
                        getStoreDetails();
                        isEnabled();
                        setSaveBtnLoad(false);
                        return response
                    } else {
                        showErrorToast();
                        setSaveBtnLoad(false);
                        throw new Error('Empty data')
                    }
                })
                .catch(function (error) {
                    //console.log(error)
                    setSaveBtnLoad(false);
                    return []
                })
        }
        // const data = {
        //     salesLedger: salesLedgerToUpdate == undefined || salesLedgerToUpdate == "" ? salesLedger.length != 0 ? salesLedger[0].label : "" : salesLedgerToUpdate.label,
        //     partyLedger: partysLedgerToUpdate == undefined || partysLedgerToUpdate == "" ? partyLedger.length != 0 ? partyLedger[0].label : "" : partysLedgerToUpdate.label,
        //     companyName: selectedCompanyName,
        //     frequency: frequency,
        //     godowns: selectedGodowns,
        //     tallyIp: tallyIp,
        //     tallyPort: tallyPort,
        //     skuField: skuField,
        //     webhookTopic: selectedWebhookTopic,
        //     ledgerStatus: value,
        //     voucherType: voucherType,
        //     tallifyStatus: active
        // };

    }

    

    function GenerateApiKey() {
        showLoader();
        api.generateAPIKey(shopOrigin.current.shop).then(response => {
            if (response) {
                api.getStore(shopOrigin.current.shop)
                    .then(data => {
                        setApiKey(data.tallyApiKey);
                        setModalActive(false);
                        showToast()
                    });
            }
            else { showErrorToast() }
            hideLoader();
        })
    }


}
