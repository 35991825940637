import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Card, FormLayout, TextField, PageActions, Form, Select, Button, AppProvider, TextStyle, ChoiceList, Subheading, Layout, Page, SettingToggle, RadioButton, Stack, Icon, Banner, SkeletonPage, SkeletonDisplayText, SkeletonBodyText, TextContainer, Modal } from '@shopify/polaris';
import useLoader from '../Loader/UseLoader';
import Api from '../Services/CommonServices';
import useSuccessToast from '../Toast/UseSuccessToast';
import useErrorToast from '../Toast/UseErrorToast';
import { environment } from '../environments/environment.prod';
// import enTranslations from '@shopify/polaris/locales/en.json';
import UseLoadingText from '../Toast/UseLoadingText';
import { TallyEmptyState } from './TallyEmptyState';
import { ArrowDownMinor, DeleteMinor, RefreshMajor, RefreshMinor } from '@shopify/polaris-icons';
import { Setting } from '../Setting';
import * as signalR from "@microsoft/signalr";
const api = new Api();
const endpoint = environment.serverUrl;
export function TallyConfigBackUp() {
  const [loader, showLoader, hideLoader] = useLoader();
  const [toastMarkup, showToast] = useSuccessToast();
  const [toastErrorMarkup, showErrorToast] = useErrorToast()
  const [verifyBtnLoad, setVerifyBtnLoad] = useState(false);
  // const [verifyPort,setVerifyPort] = useState(false);
  const [submitBtnLoad, setSubmitBtnLoad] = useState(false);
  const [loading, showLoadingText, hideLoadingText] = UseLoadingText();
  ;
  const handleSelectSalesLedgerChange = useCallback((value) => setSelectedSalesLedger(value), []);
  const handleSelectVoucherTypeChange = useCallback((value) => setSelectedVoucherType(value), []);
  const [active, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleToggle = useCallback(() => setActive((active) => !active), []);
  const contentStatus = active ? 'Disable' : 'Enable';
  const textStatus = active ? 'enabled' : 'disabled';
  const [tallyIp, setTallyIp] = useState('localhost');
  const [companyname, setCompanyName] = useState([]);
  const [tallyPort, setTallyPort] = useState('9000');
  const [apiKey, setApiKey] = useState('');
  const [systemInfo, setSystemInfo] = useState('');
  const [tallyStatus, setTallyStatus] = useState(false);
  const [frequency, setFrequency] = useState('5');
  const [isIpChanged, setIsIpChanged] = useState(false);
  const [isPortChanged, setIsPortChanged] = useState(false);
  const handleTallyIpChange = useCallback(newValue =>  { if(newValue !== tallyIp) { setIsIpChanged(true); setTallyIp(newValue) } else { setTallyIp(newValue); }}, []);
  const handleTallyPortChange = useCallback(newValue => { if(newValue !== tallyPort) { setIsPortChanged(true); setTallyPort(newValue) }  else { setTallyPort(newValue) }}, []);
  const handleFrequencyChange = useCallback(newValue => setFrequency(newValue), []);
  const [salesLedger, setSalesLedger] = useState([]);
  const [partyLedger, setPartyLedger] = useState([]);
  const [ledgerList, setLedgerList] = useState([]);
  const [voucherTypes, setVoucherTypes] = useState([]);
  const [selectedVoucherType, setSelectedVoucherType] = useState('');
  const [interval, setInterval] = useState(150000);
  const [verifyPortInterval, setVerifyPortInterval] = useState(150000);
  const [selectedCompanyName, setSelectedCompanyName] = useState('');
  const [selectedSalesLedger, setSelectedSalesLedger] = useState('');
  const [selectedPartyLedger, setSelectedPartyLedger] = useState('');
  const [existCompanyName, setExistCompanyName] = useState('');
  const [selectedGodowns, setSelectedGodowns] = useState([]);
  const [godowns, setGodowns] = useState([]);
  // const handleCompanyNameChange = useCallback((newValue,companyName,tallyIp,tallyPort) => {setSelectedGodowns([]); setSelectedCompanyName(newValue); getTallyData(companyName,tallyIp,tallyPort); }, [])
  const handleGodownCheckChange = useCallback((newChecked) => setSelectedGodowns(newChecked), []);
  const handlePartLedgerChange = useCallback((value) => setSelectedPartyLedger(value), []);
  const [skuField, setSkuField] = useState('AliasOnly');
  const [selectedWebhookTopic, setSelectedWebhookTopic] = useState('ordersCreate');
  const handleSkuFieldChange = useCallback((value) => setSkuField(value), []);
  const handleWebhookTopicChange = useCallback((value) => setSelectedWebhookTopic(value), []);
  const [value, setValue] = useState('dynamic');
  const [goBackActive, setGoBackActive] = useState(false);
  const [consoleDisConnected, setConsoleDisConnected] = useState(false);
  const [isTallyRunning, setIsTallyRunning] = useState(true);
  
  const [tallyConnected, setTallyConnected] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const handleModalChange = useCallback(() => setModalActive((modalActive) => !modalActive), []);
  const handleChange = useCallback(
    (_checked, newValue) => setValue(newValue),
    [],
  );
  const skuFieldOptions = [
    { label: 'Alias Only', value: 'AliasOnly' },
    { label: 'Name Only', value: 'NameOnly' },
    { label: 'PartNo Only', value: 'PartNoOnly' },
  ];

  const webhookTopicOptions = [
    { label: 'Orders create', value: 'ordersCreate' },
    { label: 'Orders paid', value: 'ordersPaid' },
    { label: 'Orders fulfilled', value: 'ordersFulfilled' },
  ];

  const delay = ms => new Promise(res => setTimeout(res, ms));
  const frequencyOptions = [
    { label: '5', value: '5' },
    { label: '10', value: '10' },
    { label: '15', value: '15' },
    { label: '45', value: '45' },
    { label: '60', value: '60' },
  ];
  const timer = useRef(null);
  const verifyPortTimer = useRef(null);
  const shopOrigin = useRef('');


  useEffect(() => {
    shopOrigin.current = api.QueryStringToJSON();
    // //console.log("shopOrigin=", shopOrigin);
    // getTallyConfig();
    getStore();
    // getTallyPartyLedger();
    // CheckConnectionStatus();
  }, []);



  const handleFormSubmit = (evt) => {
    evt.preventDefault();

    if (existCompanyName !== selectedCompanyName) { deleteTallyResponse(); }
    addTallyConfigs();
    // getTallyPartyLedger();
    // getTallyConfig();
  }




  const handleVerifyPort = (evt) => {
    setVerifyBtnLoad(true);
    evt.preventDefault(tallyIp, tallyPort);
    api.tallyStatus(shopOrigin.current.shop, tallyIp, tallyPort);
  }

  function verifyPort(tallyip, tallyPort) {
    api.tallyStatus(shopOrigin.current.shop, tallyip, tallyPort);
  }

  const handleFormToggle = (evt) => {
    evt.preventDefault();
    addTallyConfigs();
  }
  // const handleCompanyNameChange = useCallback((newValue,companyName,tallyIp,tallyPort) 
  // => {setSelectedGodowns([]); 
  //   setSelectedCompanyName(newValue); 
  //   getTallyData(companyName,tallyIp,tallyPort); }, [])

  // function handleCompanyNameChange(companyName, tallyIp, tallyPort) {
  //   // //console.log("values=", companyName, tallyIp, tallyPort);
  //   getTallyData(companyName, tallyIp, tallyPort);
  // }

  // const CallVerifyTimer = async () => {
  //   showLoader();
  //   await delay(5000);
  //   TimerPortVerify();
  // };

  function getTallyConfig(tallyData) {
    //console.log("getTallyConfig=", tallyData.length)
    if (tallyData.length > 0) {
      var data = tallyData[0];
      //console.log("getTallyConfig=", data.tallifyStatus)
      setIsLoading(true);
      if (data.length !== 0) {
        setActive(data.tallifyStatus === 1 ? true : false)
        setSelectedVoucherType('' + data.voucherType);
        setExistCompanyName('' + data.companyName);
        setTallyIp('' + data.tallyIP)
        setTallyPort('' + data.tallyPort)
        setFrequency('' + data.frequency);
        setSelectedSalesLedger('' + data.salesLedger)
        setSelectedPartyLedger('' + data.partyLedger)
        data.ledgerStatus !== "" ? setValue('' + data.ledgerStatus) : setValue('fixed');
        data.godowns != null ? setSelectedGodowns(data.godowns) : setSelectedGodowns("");
        if (data.companyName !== "" || data.tallyIP !== "" || data.tallyPort !== "") {
          api.tallyConnectionStatus(shopOrigin.current.shop).then(response => {
            //console.log("tally status=", response.data);
            setTallyConnected(response.data.connectionStatus);
            setSystemInfo(response.data.connectedSystem);
            setApiKey(response.data.apiKey);
            if (response.data.connectionStatus) {
              getTallyData(data.companyName, data.tallyIP, data.tallyPort);
            }
          })

        }
        return true;
      }
    } else { return false;}
  }

  // function CheckConnectionStatus() {
  //   api.CheckConnectionStatus(shopOrigin.current.shop)
  //     .then(response => {
  //       if (response !== "error") {
  //         setVerifyPortInterval(200000)
  //         hideLoader();
  //         setIsLoading(false);
  //         setTallyStatus(response);
  //       }
  //       else {
  //         TimerPortVerify();
  //       }
  //     });
  // }

  async function getStore() {
    showLoader();
    api.getStore(shopOrigin.current.shop)
      .then(data => {
        var res = getTallyConfig(data.tallyConfigs);
        if (res) {
          const hubConnection = new signalR.HubConnectionBuilder()
              .withUrl(environment.serverUrl + "/tally-hub")
              .configureLogging(signalR.LogLevel.Information)
              .build();
            hubConnection.start().then(a => {
              // Once started, invokes the sendConnectionId in our ChatHub inside our ASP.NET Core application.
              if (hubConnection.connectionId) {
                hubConnection.invoke("OnConnectedFrontEnd", hubConnection.connectionId, shopOrigin.current.shop);
              }
            }).catch(function(error) {
            });
            
            hubConnection.onclose(() => {
              showErrorToast(); setTimeout( function() { window.location.reload(false) }, 3000);
           })

            hubConnection.on("tallyResponse", message => {
            });

            hubConnection.on("salesLedger", message => {
              setSalesLedger([]);
              const salesLedgerOptions = []
              message.forEach(per => {
                const salesledger = {
                  value: per.salesLedgerName, label: per.salesLedgerName
                };
                salesLedgerOptions.push(salesledger)
              })
              setSalesLedger(salesLedgerOptions);

            });


            hubConnection.on("partyLedger", message => {
              setPartyLedger([]);
              const partyLedgerOptions = [];
              message.forEach(per => {

                const ledger = {
                  value: per.partyLedgerName, label: per.partyLedgerName
                };
                partyLedgerOptions.push(ledger)
              })
              setPartyLedger(partyLedgerOptions);
            });

            hubConnection.on("ledgerList", message => {
              setLedgerList([]);
              const ledgerListOptions = [];
              message.forEach(per => {

                const ledger = {
                  value: per.ledgerName, label: per.ledgerName
                };
                ledgerListOptions.push(ledger)
              })
              setLedgerList(ledgerListOptions);
            });

            hubConnection.on("godownList", message => {
              setGodowns([]);
              const godownOptions = []
              message.forEach(per => {
                const godown = {
                  value: per.godownName, label: per.godownName
                };
                godownOptions.push(godown)
                setGodowns(godownOptions);
                hideLoader()
              })
            });

            hubConnection.on("tallyCompanies", message => {
              // setCompanyName([]);
              //console.log("company name =", message);
              setSelectedCompanyName('' + message);
              // setCompanyName(message);
              const companyOptions = []
              // message.forEach(com => {
              //   //console.log("company name =",com.companyName);
              //   setSelectedCompanyName(''+com.companyName);
              //   const data = {
              //     value: com.companyName, label: com.companyName
              //   };
              //   companyOptions.push(data);
              // })
              // setCompanyName(companyOptions);
            });

            hubConnection.on("voucherTypes", message => {
              setVoucherTypes([]);
              // setCompanyName(message);
              const voucherTypeOptions = []
              message.forEach(com => {
                const data = {
                  value: com.voucherTypeName, label: com.voucherTypeName
                };
                voucherTypeOptions.push(data);
              })
              setVoucherTypes(voucherTypeOptions);
            });
            hubConnection.on("tallyStatus", response => {
              setIsIpChanged(false);setIsPortChanged(false);
              setVerifyBtnLoad(false);
              setTallyStatus(response);

              // setTime(message);
            });

            hubConnection.on("ConsoleconnectionDIsconnect", response => {
              //console.log("ConsoleconnectionDIsconnect called",response);
              setConsoleDisConnected(response); if(!response) { getStore(); }
              setTallyConnected(!response);
            });

            hubConnection.on("TallyErpStatus", response => {
              setIsTallyRunning(response);
              hideLoader();
              // setTime(message);
            });
            hideLoader();
          }
      });
  }

  async function deleteTallyResponse() {
    api.deleteTallyResponse(shopOrigin.current.shop);
  }

  async function getTallyData(companyName, tallyIp, tallyPort) {
    showLoader();
    // //console.log("tally data=", companyName, tallyIp, tallyPort);
    setCompanyName([]);
    setSalesLedger([]);
    setPartyLedger([]);
    setGodowns([]);
      api.getTallyResponse(shopOrigin.current.shop, "CurrentCompany", companyName, tallyIp, tallyPort).then(response => {
        api.getTallyResponse(shopOrigin.current.shop, "PartyLedger", companyName, tallyIp, tallyPort)
        api.getTallyResponse(shopOrigin.current.shop, "SalesLedger", companyName, tallyIp, tallyPort)
        // api.getTallyResponse(shopOrigin.current.shop, "LedgerList", companyName, tallyIp, tallyPort)
        api.getTallyResponse(shopOrigin.current.shop, "GodownList", companyName, tallyIp, tallyPort)
        api.getTallyResponse(shopOrigin.current.shop, "VoucherType", companyName, tallyIp, tallyPort)
      });
    // .then(data => {
    //   if (data[0] !== undefined) {
    //     if (selectedCompanyName === undefined) { setSelectedCompanyName('' + data[0].companyName); }
    //   }
    //   setCompanyName(data);
    // });
  }


  // function TimerGetLedgers() {
  //   setIsLoading(true);
  //   showLoader();
  //   timer.current = setInterval(() =>

  //     getTallyPartyLedger(), interval);
  //   return () => {
  //     clearInterval(timer.current);
  //   };
  // }

  // function TimerPortVerify() {
  //   setIsLoading(true);
  //   showLoader();
  //   verifyPortTimer.current = setVerifyPortInterval(() =>

  //     CheckConnectionStatus(), verifyPortInterval);
  //   return () => {
  //     clearInterval(timer.current);
  //   };
  // }


  // async function getTallyPartyLedger() {
  //   api.getTallyPartyLedger(shopOrigin.current.shop)
  //     .then(function (response) {
  //       if (response.status === 200 && response != null) {
  //         getTallyConfig();
  //         setInterval(200000);
  //         hideLoader();
  //         setIsLoading(false);
  //         hideLoadingText();
  //         if (response.data[0] !== undefined) {
  //           setSelectedPartyLedger('' + response.data[0].partyLedgerName)
  //           setPartyLedger(response.data);
  //         }
  //       }
  //       else {
  //         showLoadingText()
  //         TimerGetLedgers();
  //       }
  //     });
  // }

  // async function getTallySalesLedger() {
  //   api.getTallySalesLedger(shopOrigin.current.shop)
  //     .then(data => {
  //       setSalesLedger(data);
  //     });
  // }

  // async function getTallyGodowns() {
  //   api.getTallyGodowns(shopOrigin.current.shop)
  //     .then(function (response) {
  //       if (response.status === 200 && response != null) {
  //         setGodowns(response.data)
  //         hideLoader();
  //         setIsLoading(false);
  //         hideLoadingText();
  //       }
  //       else {
  //         showLoadingText()
  //         TimerGetLedgers();
  //       }
  //     })
  // }

  async function addTallyConfigs() {
    setSubmitBtnLoad(true);
    var voucherType = selectedVoucherType == '' ? voucherTypes.length !== 0 ? voucherTypes[0].value : "" : selectedVoucherType;
    var salesLedgerToUpdate = salesLedger.length != 0 ? salesLedger.find(x => x.label == selectedSalesLedger) : "";
    var partysLedgerToUpdate = partyLedger.length != 0 ? partyLedger.find(x => x.label == selectedPartyLedger) : "";
    // //console.log("salesLedgerToUpdate=",salesLedgerToUpdate);
    // var partysLedgerToUpdate = partyLedger.length != 0 ? partyLedger.find(x => x.value == selectedPartyLedger).value : "";

    const data = {
      salesLedger: salesLedgerToUpdate == undefined || salesLedgerToUpdate == "" ? salesLedger.length != 0 ? salesLedger[0].label : "" : salesLedgerToUpdate.label,
      partyLedger: partysLedgerToUpdate == undefined || partysLedgerToUpdate == "" ? partyLedger.length != 0 ? partyLedger[0].label : "" : partysLedgerToUpdate.label,
      companyName: selectedCompanyName,
      frequency: frequency,
      godowns: selectedGodowns,
      tallyIp: tallyIp,
      tallyPort: tallyPort,
      skuField: skuField,
      webhookTopic: selectedWebhookTopic,
      ledgerStatus: value,
      voucherType: voucherType,
      tallifyStatus: active
    };
    // //console.log("voucherTypes)=",voucherTypes )
    // //console.log("JSON.stringify(data)=",JSON.stringify(data))
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    fetch(endpoint + '/api/TallyConfig/add?shopUrl=' + shopOrigin.current.shop, requestOptions)
      .then(function (response) {
        if (response.status === 200 && response != null) {
          showToast();
          setSubmitBtnLoad(false);
          return response
        } else {
          showErrorToast();
          setSubmitBtnLoad(false);
          throw new Error('Empty data')
        }
      })
      .catch(function (error) {
        //console.log(error)
        setSubmitBtnLoad(false);
        return []
      })
  }

  function GenerateApiKey() {
    showLoader();
    api.generateAPIKey(shopOrigin.current.shop).then(response => {
      if(response) { api.getStore(shopOrigin.current.shop)
        .then(data => {
          setApiKey(data.tallyApiKey);
          setModalActive(false);
          showToast()
        });
      }
      else { showErrorToast() }
      hideLoader();
    })
  }







  // const downloadBanner = (
  //   <Banner
  //     title="Console App"
  //     action={{ content: <Icon source={ArrowDownMinor} />, onAction: () => { window.open('https://drive.google.com/file/d/1H-hwW4dN4KWIjDEj1n8tz2nsc-p7jAT4/view?usp=sharing') } }}
  //     status="info"
  //   >
  //     <p>Please download console app.</p>
  //   </Banner>
  // );

  const apiKeyConfirmationModal = ( 
    <div style={{ height: '500px' }}>
      <Modal
        open={modalActive}
        onClose={handleModalChange}
        title="Are you sure you want to generate new API key ?"
        primaryAction={{
          destructive: true,
          content: 'Generate new api key',
          onAction: GenerateApiKey,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleModalChange,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>
             This will remove all the connections between client and server.<br/>
             This action cannot be reversed.
             
      </p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  );

  const tallySettings = (
    <>
      <Layout>
        <Layout.AnnotatedSection>
          <Card sectioned>
            <Stack wrap={false} alignment="leading" spacing="loose">
              <Stack.Item fill>
                <FormLayout>
                  <FormLayout.Group condensed>
                    <TextField helpText="Please click on refresh to update the company name after tally company change"
                      label="Selected company name in tally"
                      type="text" value={selectedCompanyName} readOnly={true} />
                    {/* <Select
                        label="Selected company name in tally"
                        helpText="Please click on refresh to update the company name after tally company change"
                        options={companyname}
                        onChange={(e) => {setSelectedCompanyName(e);handleCompanyNameChange(e, tallyIp, tallyPort);}}
                        value={selectedCompanyName}
                        disabled={true}
                      /> */}
                  </FormLayout.Group>
                </FormLayout>
                <div style={{ marginTop: "4px" }}>
                </div>
              </Stack.Item>
              <div style={{ paddingTop: "24px" }}>
                <Button primary onClick={() => getTallyData(selectedCompanyName, tallyIp, tallyPort)} icon={RefreshMinor} accessibilityLabel="Refresh" />
              </div>
            </Stack><br />
          </Card>
        </Layout.AnnotatedSection></Layout>
      <Layout>
        <Layout.AnnotatedSection>
          <Card></Card>
        </Layout.AnnotatedSection>
      </Layout>
      <Layout>
        <Layout.AnnotatedSection
          title="Inventory sync"
          description="Customize your app settings">
          <Card sectioned>
            <FormLayout>
              <FormLayout.Group>
                <>
                  <Select
                    label="Select tally sync frequency(mins)"
                    options={frequencyOptions}
                    onChange={handleFrequencyChange}
                    value={frequency}
                    disabled={false}
                    helpText="Set frequency for auto sync in minutes"
                  /><br />
                </>
                <>
                  <Select
                    label="Sku field in item"
                    helpText="Location of an sku in a tally stock item(case sensitive)"
                    options={skuFieldOptions}
                    onChange={handleSkuFieldChange}
                    value={skuField}
                  /> <br /></>
              </FormLayout.Group>
            </FormLayout>
            <div><Subheading>Tally Godowns</Subheading><br></br>
              <ChoiceList
                allowMultiple
                title="Select the godowns"
                choices={godowns}
                selected={selectedGodowns}
                onChange={handleGodownCheckChange}
              />
            </div>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>

      {/* <Layout>
                <Layout.AnnotatedSection>
                  <Card></Card>
                </Layout.AnnotatedSection>
              </Layout> */}
      {/* <Layout>
                <Layout.AnnotatedSection
                  title="Order Sync"
                  description="Customize your app settings">

                  <Card sectioned>
                    <FormLayout>
                      <FormLayout.Group>
                        <Select
                          label="Select order sync topic"
                          helpText="Helps to sync orders based on the order status"
                          options={webhookTopicOptions}
                          onChange={handleWebhookTopicChange}
                          value={selectedWebhookTopic}
                        />
                        <Select
                          label="Select sales ledger"
                          options={salesLedger}
                          onChange={handleSelectSalesLedgerChange}
                          value={selectedSalesLedger}
                          disabled={false}
                        />
                      </FormLayout.Group>
                      <Select
                          label="Select voucher type"
                          options={voucherTypes}
                          onChange={handleSelectVoucherTypeChange}
                          value={selectedVoucherType}
                          disabled={false}
                        />
                      <Stack vertical>
                        <RadioButton
                          label="Party ledger dynamic(autogenerate ledger based on orders)"
                          helpText="Ledger name will be billing email."
                          id="dynamic"
                          name="ledger"
                          checked={value === 'dynamic'}
                          onChange={handleChange}
                        />
                        <RadioButton
                          label="Party ledger fixed(based on ledger selection)"
                          helpText="Use when party ledger is constant."
                          checked={value === 'fixed'}
                          id="fixed"
                          name="ledger"
                          onChange={handleChange}
                        />

                      </Stack>
                      {value === "fixed" ?
                        <Select
                          label="Select Party ledger"
                          options={partyLedger}
                          onChange={handlePartLedgerChange}
                          value={selectedPartyLedger}
                          disabled={false}
                        /> : ""
                      }
                    </FormLayout>
                  </Card>
                </Layout.AnnotatedSection>
              </Layout> */}

      {toastMarkup}
      {toastErrorMarkup}

      <Layout>
        <Layout.AnnotatedSection>
          <Card></Card>
          {consoleDisConnected ? <><br />
            <Banner
              title="Client app disconnected.Try again later."
              status="critical"
            >
            </Banner></> : ""}
          {!isTallyRunning ? <><br />
            <Banner
              title="No connection could be made. Tally ERP is not runnning"
              status="critical"
            >
            </Banner></> : ""}
          <PageActions
            primaryAction={{
              content: 'Save',
              submit: true,
              loadin: submitBtnLoad,
              disabled: consoleDisConnected
            }} />
        </Layout.AnnotatedSection>
      </Layout>
    </>
  );
  return (
    <>
      {goBackActive == false ?
        <>
          {loader}
          <Layout>
            <Layout.AnnotatedSection
            >
              {/* {downloadBanner}
              <br /> */}
              <Card>

                <Form onSubmit={handleFormToggle}>
                  <SettingToggle
                    action={{
                      content: contentStatus,
                      onAction: handleToggle,
                      submit: true
                    }}
                    enabled={active}>
                    Tallify is <TextStyle variation="strong">{textStatus}</TextStyle>.
                </SettingToggle>
                </Form>
              </Card>
              <Card></Card>
            </Layout.AnnotatedSection>
          </Layout>
          {active === true ?
            <Form onSubmit={handleFormSubmit}>
              <Layout>
                <Layout.AnnotatedSection
                  title="Tally Config"
                  description="Customize your app settings.">

                  <Card sectioned>
                    <Stack alignment="leading">
              <Stack.Item fill>
              <TextField helpText="Use this API key to setup the tallify console app" label="API key" type="text" value={apiKey} readOnly={true} />
              </Stack.Item>
                    <div style={{ paddingTop: "23px" }}>
                      <Button plain onClick={handleModalChange}>generate key</Button>
                    </div>
                  </Stack>
                    {/* <TextField helpText="Use this API key to setup the tallify console app" label="API key" type="text" value={apiKey} disabled /> */}
                    <TextStyle variation="subdued">Install a service:  TallySync.exe install</TextStyle>
                    <br /><TextStyle variation="subdued">Start a service:  TallySync.exe start</TextStyle>
                    <br /><TextStyle variation="subdued">Uninstall a service:  TallySync.exe uninstall</TextStyle>
                    <br /><TextStyle variation="subdued">Stop a service:  TallySync.exe stop</TextStyle>
                    <br /><br />
                    <FormLayout>
                      <FormLayout.Group condensed>
                        <TextField label="Enter the tally-ip" type="text" placeholder="localhost" helpText="tally-ip (eg:localhost)" id="tally-ip" value={tallyIp} onChange={handleTallyIpChange} />
                        <TextField label="Enter the tally-port" type="number" placeholder="9000" helpText="tally-port (eg:9000)" id="tally-port" value={tallyPort} onChange={handleTallyPortChange} />

                        <div style={{ paddingTop: "24px" }}>
                          <Button
                            disabled={(tallyIp === '' || tallyPort === '' ) || tallyConnected == false ? true : false}
                            primary={true}
                            loading={verifyBtnLoad}
                            fullWidth={true}
                            onClick={handleVerifyPort}>
                            Verify</Button>
                        </div>
                      </FormLayout.Group>
                      {tallyStatus === true && isTallyRunning === true && consoleDisConnected === false && isIpChanged === false && isPortChanged === false ?
                        <Banner
                          title={<TextStyle variation="positive">Tally is running on port number: {tallyPort}</TextStyle>}
                          status="success"></Banner> : isTallyRunning && isIpChanged === false && isPortChanged === false && consoleDisConnected === false && tallyConnected?
                          <Banner
                            title={<TextStyle variation="negative">Tally is not running on port number: {tallyPort}</TextStyle>}
                            status="critical"></Banner> : ""
                      }
                      {consoleDisConnected === false && tallyConnected ?
                        <Banner
                          title={<TextStyle variation="positive">Connected system: {systemInfo}</TextStyle>}
                          status="success"></Banner> :
                          <Banner
                          title={<TextStyle variation="negative">Client app disconnected.Try again later.</TextStyle>}
                          status="critical"
                        ></Banner>
                      }
                      {!isTallyRunning ? <>
                        <Banner
                          title="No connection could be made. Tally ERP is not runnning"
                          status="critical"
                        >
                        </Banner></> : ""}
                    </FormLayout>
                  </Card>
                </Layout.AnnotatedSection></Layout><br />
              {tallyStatus && !isIpChanged  && !isPortChanged  && !consoleDisConnected ? <>{tallySettings} {modalActive ? apiKeyConfirmationModal : ""}  </>: undefined}
            </Form> : <Layout><Layout.AnnotatedSection><TallyEmptyState /></Layout.AnnotatedSection></Layout>}
            </> : <Setting /> }
    </>

  );

  function goBack() {
    setGoBackActive(true);
  }
}
