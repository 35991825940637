import { AppProvider, Banner, Button, CalloutCard, Card, Collapsible, Icon, Layout, Link, Modal, Page, Stack, TextContainer, TextField, TextStyle } from '@shopify/polaris';
import { ArrowDownMinor, KeyMajor, RefreshMinor } from '@shopify/polaris-icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { environment } from './environments/environment.prod';
import { JsonSetup } from './JsonDataSetup/JsonSetup';
import useLoader from './Loader/UseLoader';
import { QboSetting } from './QuickbooksSetup/QboSetting';
import Api from './Services/CommonServices';
import { TallyConfig } from './TallySetup/TallyConfigBackUp';
import { TallySetup } from './TallySetup/TallySetup';
import useErrorToast from './Toast/UseErrorToast';
import useSuccessToast from './Toast/UseSuccessToast';
import tallyLogo from './../assets/tally-erp-logo.png';
import quickbooksLogo from './../assets/quickbooks-logo.png';
import jsonLogo from './../assets/json-logo.png';
const api = new Api();
const endpoint = environment.serverUrl;
export function Setting() {


  const [active, setActive] = useState(false);

  const handleChange = useCallback(() => setActive(!active), [active]);

  const activator = <Button onClick={handleChange}>Open</Button>;
  const [qboSetup, setQboSetUp] = useState(false);
  const [loader, showLoader, hideLoader] = useLoader();
  const [tallySetup, setTallySetUp] = useState(false);
  const [jsonSetup, setJsonSetup] = useState(false);
  const shopOrigin = useRef('');
  const [companyName, setCompanyName] = useState('');
  const [connected, setConnected] = useState(false);
  const [tallyConnected, setTallyConnected] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const details = connected ? companyName + 'Quickbooks connected' : 'Quickbooks not connected';
  const tallyDetails = tallyConnected ? 'Connected to' : 'No Tally host connected';
  const [toastMarkup, showToast] = useSuccessToast();
  const [systemInfo, setSystemInfo] = useState('');
  const [toastErrorMarkup, showErrorToast] = useErrorToast();
  useEffect(() => {
    shopOrigin.current = api.QueryStringToJSON();
    // //console.log("settings shop=", shopOrigin.current)
    // api.getStore(shopOrigin.current.shop)
    //   .then(data => {
    //     setSystemInfo(data.signalRConnections[0].systemInfo);
    //     setApiKey(data.tallyApiKey);
    //   });
    QboAppData();
    // DeleteConnection();
    TallyConnectionStatus();
  }, []);

  const qboSetting = (
    <QboSetting />
  );

  const tallySetting = (
    <TallySetup />
  );

  const jsonSetting = (
    <JsonSetup />
  );


  function QboAppData() {
    // showLoader();
    api.QboAppData(shopOrigin.current.shop).then(response => {
      //console.log(response.companyName)
      response.companyName !== undefined ? setConnected(true) : setConnected(false)
      setCompanyName(response.companyName);
    })
    // hideLoader();
  }

  function TallyConnectionStatus() {
    showLoader();
    api.tallyConnectionStatus(shopOrigin.current.shop).then(response => {
      hideLoader();
      //console.log("response connection status=",response.data);
      setTallyConnected(response.data.connectionStatus);
      setSystemInfo(response.data.connectedSystem);
      setApiKey(response.data.apiKey);
    })
    // hideLoader();
  }


  function GenerateApiKey() {
    showLoader();
    api.generateAPIKey(shopOrigin.current.shop).then(response => {
      if(response) { api.getStore(shopOrigin.current.shop)
        .then(data => {
          setApiKey(data.tallyApiKey);
          setActive(false);
          showToast()
        });
      }
      else { showErrorToast() }
      hideLoader();
    })
  }



const apiKeyConfirmationModal = ( 
                                  <div style={{ height: '500px' }}>
                                    <Modal
                                      open={active}
                                      onClose={handleChange}
                                      title="Are you sure you want to generate a new API key?"
                                      primaryAction={{
                                        destructive: true,
                                        content: 'Generate API key',
                                        onAction: GenerateApiKey,
                                      }}
                                      secondaryActions={[
                                        {
                                          content: 'Cancel',
                                          onAction: handleChange,
                                        },
                                      ]}
                                    >
                                      <Modal.Section>
                                        <TextContainer>
                                          <p>
                                           This will remove all existing connections between client and server.<br/>
                                           This action cannot be reversed.</p>
                                        </TextContainer>
                                      </Modal.Section>
                                    </Modal>
                                  </div>
                                );

  
  const downloadBanner = (
    <Banner
      title="Console App"
      action={{ content: <Icon source={ArrowDownMinor} />, onAction: () => { window.open('https://drive.google.com/u/0/uc?id=1e5Kgp0A_n4SQ-q299p7u9Goy1s3LyvvU&export=download') } }}
      status="info"
    >
      <p>Please download the Tallify App and follow the instructions below</p>
      <ul>
        <li>Step 1. Run the exe file, it will open a terminal window.</li>
        <li>Step 2. Enter the copy the API key provided above and paste in the terminal.</li>
        <li>Step 3. Head back to our Shopify App to complete the rest of the setup.</li>
      </ul>
    </Banner>
  );

  // const [openStep1, setOpenStep1] = useState(false);
  // const handleToggleStep1 = useCallback(() => setOpenStep1((openStep1) => !openStep1), []);
  // const step1 = (
  //       <>
  //         <Button
  //           fullWidth
  //           onClick={handleToggleStep1}
  //           ariaExpanded={openStep1}
  //           ariaControls="basic-collapsible">
  //           Step 1: Download
  //         </Button>
  //         <Collapsible
  //           open={openStep1}
  //           id="basic-collapsible"
  //           transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
  //           expandOnPrint>
  //           <TextContainer>
  //             <p>
  //               Download console application <Link url="https://drive.google.com/u/0/uc?id=1WMWv74nlwdddNmOf9ObLW9Pla4-G0AQs&export=download">Download</Link>
  //             </p>
  //           </TextContainer>
  //         </Collapsible>
  //       </>
  // );

  // const [openStep2, setOpenStep2] = useState(false);
  // const handleToggleStep2 = useCallback(() => setOpenStep2((openStep2) => !openStep2), []);
  // const step2 = (
  //       <>
  //         <Button
  //           fullWidth
  //           onClick={handleToggleStep2}
  //           ariaExpanded={openStep2}
  //           ariaControls="basic-collapsible">
  //           Step 2: Install
  //         </Button>
  //         <Collapsible
  //           open={openStep2}
  //           id="basic-collapsible"
  //           transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
  //           expandOnPrint>
  //           <TextContainer>
  //           <TextField helpText="Use this API key to setup the tallify console app" label="API key" type="text" value={apiKey} readOnly={true} />
  //           <TextStyle >Install a service:  TallySync.exe install</TextStyle>
  //               <br /><TextStyle>Start a service:  TallySync.exe start</TextStyle>
  //               <br /><TextStyle>Uninstall a service:  TallySync.exe uninstall</TextStyle>
  //               <br /><TextStyle>Stop a service:  TallySync.exe stop</TextStyle>
  //           </TextContainer>
  //         </Collapsible>
  //       </>
  // );






  const homePage = (
    <Layout sectioned>
      <Layout.AnnotatedSection
        title="Setup"
        description="Complete your setup by enabling the integration and saving the config">

        <CalloutCard
          title="Configure Tally"
          illustration={tallyLogo}
          primaryAction={{
            content: 'Tally setup',
            onAction: () => OpenTallySetup(),
          }}
          secondaryAction={{
            content:'Refresh',
            onAction: () => TallyConnectionStatus()
          }}

        >
          <p>You are a few steps away from using Tally sync. Click Setup below to start.</p>
          <TextContainer>
            {tallyConnected == true ?
              <>
              <TextField helpText="Use this API key to setup the tallify console app" label="API key" type="text" value={apiKey} readOnly={true} />
                <TextStyle variation="subdued">Install a service:  TallySync.exe install</TextStyle>
                <br /><TextStyle variation="subdued">Start a service:  TallySync.exe start</TextStyle>
                <br /><TextStyle variation="subdued">Uninstall a service:  TallySync.exe uninstall</TextStyle>
                <br /><TextStyle variation="subdued">Stop a service:  TallySync.exe stop</TextStyle>
                    <Banner onDismiss={false} status="success">
                      <p>
                        {tallyDetails + ": "+systemInfo}
                      </p>
                    </Banner>
                </>

              : <>{downloadBanner} 
              <TextField helpText="Use this API key to setup the tallify console app" label="API key" type="text" value={apiKey} readOnly={true} />
                <TextStyle variation="subdued">Install a service:  TallySync.exe install</TextStyle>
                <br /><TextStyle variation="subdued">Start a service:  TallySync.exe start</TextStyle>
                <br /><TextStyle variation="subdued">Uninstall a service:  TallySync.exe uninstall</TextStyle>
                <br /><TextStyle variation="subdued">Stop a service:  TallySync.exe stop</TextStyle>
                  <Stack alignment="leading">
                    <Stack.Item fill>
                      <Banner onDismiss={false} status="critical"> <p>{tallyDetails}</p></Banner>
                    </Stack.Item>
                    <div style={{ paddingTop: "7px" }}>
                      <Button primary onClick={() => TallyConnectionStatus()} icon={RefreshMinor} accessibilityLabel="Refresh" />
                    </div>
                  </Stack>
              </>
            }
          </TextContainer>
              {loader}
        </CalloutCard>

        <CalloutCard
          title="Configure Quickbooks"
          illustration={quickbooksLogo}

          primaryAction={{
            content: 'Quickbooks setup',
            onAction: () => OpenQboSetup(),
          }}
        >
          <p>You are a few steps away from using Quickbooks sync. Click Setup below to start.</p>
          <TextContainer>
            {connected == true ?
              <Banner onDismiss={false} status="success">
                <p>
                  {details}
                </p>
              </Banner>
              : <Banner onDismiss={false} status="critical">
                <p>
                  {details}
                </p>
              </Banner>}
          </TextContainer>
        </CalloutCard>

        <CalloutCard
          title="Configure JSON import"
          illustration={jsonLogo}

          primaryAction={{
            content: 'Json import setup',
            onAction: () => OpenJsonSetup(),
          }}
        >
          <p>You are a few steps away from using JSON import. Click Setup below to start.</p>
          <TextContainer>
          </TextContainer>
        </CalloutCard>
      </Layout.AnnotatedSection>
    </Layout>
  );


// if(active)  return( <> {apiKeyConfirmationModal} {toastErrorMarkup} </>); else 
  if (qboSetup === false && tallySetup === false && jsonSetup == false) {
    return (<Page > {homePage} </Page>)
  }
  else if (tallySetup === true) {
    return (<> {tallySetting} </>)
  }
  else if (qboSetup === true) {
    return (<> {qboSetting} </>)
  }
  else if (jsonSetup === true) {
    return (<> {jsonSetting} </>)
  }
  else { return (<Page > {homePage} </Page>) }


  // return (
  //   <>
  //     { (qboSetup == false && tallySetup == false) ? 
  //     <Page > {homePage} </Page> : tallySetup == true ? tallySetting : qboSetup == true ? qboSetting : <Page> {homePage} </Page>}
  //     {toastMarkup}
  //   </>
  // );


  function OpenQboSetup() {
    setQboSetUp(true);
  }

  function OpenTallySetup() {
    setTallySetUp(true);
  }

  function OpenJsonSetup() {
    setJsonSetup(true);
  }


  // function DeleteConnection() {
  //   api.DeleteConnectionId(shopOrigin.current.shop).then(response => {
  //     //console.log("delete connection res=", response.data);
  //   })
  // }

// }
} 
