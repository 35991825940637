import React, { useCallback, useState, useEffect, useRef } from 'react';
import { ChoiceList, Scrollable, FormLayout, PageActions, Form, Caption, Subheading, Card, Select, Modal, TextContainer, Button, Heading, Stack } from '@shopify/polaris';
import useLoader from '../Loader/UseLoader';
import Api from '../Services/CommonServices';
import useSuccessToast from '../Toast/UseSuccessToast';
import useErrorToast from '../Toast/UseErrorToast';
import { environment } from '../environments/environment.prod';
const api = new Api();
const endpoint = environment.serverUrl;
export function QboConfig() {

  const [loader, showLoader, hideLoader] = useLoader();
  const [toastMarkup, showToast] = useSuccessToast();
  const [toastErrorMarkup, showErrorToast] = useErrorToast()
  const [accSelected, setAccSelected] = useState();
  const [selectedAccName, setSelectedAccName] = useState("Not selected");
  const [selectedCusName, setSelectedCusName] = useState("Not selected");
  // const handleAccSelectChange = useCallback((value) => { ////console.log(value); setAccSelected(value), [] });
  let [accounts, setAccounts] = useState([]);
  let [customers, setCustomers] = useState([]);
  const [customerSelected, setCustomerSelected] = useState();
  // const handleCustomerSelectChange = useCallback((value) => setCustomerSelected(value), []);
  const [btnLoad, setBtnLoad] = useState();
  const shopOrigin = useRef('');
  const [activeAccountModal, setActiveAccountModal] = useState(false);
  const [activeCustModal, setActiveCustModal] = useState(false);
  const handleAccountModalChange = useCallback(() => setActiveAccountModal(!activeAccountModal), [activeAccountModal]);
  const handleCustModalChange = useCallback(() => setActiveCustModal(!activeCustModal), [activeCustModal]);

  // const activator = <Button onClick={handleChange}>Open</Button>;
  useEffect(() => {
    shopOrigin.current = api.QueryStringToJSON();
    ////console.log("shopOrigin=",shopOrigin);
    getQBOConfig();
  }, []);

  async function getQBOAccounts() {
    showLoader();
    api.getQBOAccounts(shopOrigin.current.shop)
      .then(response => {
        setAccounts(response)
        ////console.log("accounts=",response)
        hideLoader();
      })
  }

  function handleAccSelectChange(value)
  {
    ////console.log("acc selected=",value);
    setAccSelected(value);
  }
  
  function handleCustomerSelectChange(value)
  {
    ////console.log("cus selected=",value);
    setCustomerSelected(value);
  }

  async function getQBOConfig() {
    showLoader();
    api.getQboConfigs(shopOrigin.current.shop)
      .then(response => {
        ////console.log("config response=",response)
        if (response != null) {
          if (response.qboAccountId !== 0) { setAccSelected('' + response.qboAccountId) }
          if (response.qboAccountId !== 0) { setCustomerSelected(response.customerGraphqlId) }
          setSelectedAccName(response.qboAccountName);
          setSelectedCusName(response.customerName);
          hideLoader();
          
        }
        else
        {
          hideLoader();
        }
      })
  }

  async function getCustomers() {
    showLoader();
    api.getCustomers(shopOrigin.current.shop)
      .then(response => {
        setCustomers(response)
        hideLoader();
      })
  }

  async function addQboConfigs(acc, cust,accountName,customerName) {
    setBtnLoad(true)
    var account = acc
    var customer = cust
    ////console.log("add config json=",account,customer,accountName,customerName)
    const data = {
      QboAccountId: account,
      CustomerGraphqlId: customer,
      CustomerName: customerName,
      QboAccountName: accountName,
      Shop: shopOrigin.current.shop,
    };
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    // ////console.log(requestOptions)
    fetch(endpoint + '/api/QBOConfig/add-update-QboConfig', requestOptions)
      .then(function (response) {
        if (response.status === 200 && response != null) {
          setBtnLoad(false);
          getQBOConfig();
          setActiveAccountModal(false);
          setActiveCustModal(false);
          showToast();
          return response
        } else {
          showErrorToast();
          throw new Error('Empty data')

        }
      })
      .catch(function (error) {
        ////console.log(error)
        return []
      })
  }
  const handleFormSubmit = (evt) => {
    
    evt.preventDefault();
    ////console.log("Acc selected=",accSelected,customerSelected)
    var accountName = accounts.length !==0 ? accSelected === null || accSelected === undefined || accSelected === "Not selected" ? accounts[0].label : accounts.find(x => x.value === accSelected).label : selectedAccName;
    var customerName = customers.length !==0 ?  customerSelected === null || undefined || customerSelected === "Not selected" ? customers[0].label : customers.find(x => x.value === customerSelected).label : selectedCusName;
    addQboConfigs(accSelected, customerSelected,accountName,customerName);
  }

  const handleCustomerSelectFormSubmit = (evt) => {
    
    evt.preventDefault();
    ////console.log("accounts=",accounts)
    ////console.log("customers=",customers,customerSelected,accounts[0].label,customers[0].label)
    var accountName = selectedAccName;
    var customerName = customers.length !==0 ?  customerSelected === null || accSelected === undefined || accSelected === "Not selected" ? customers[0].label : customers.find(x => x.value === customerSelected).label : selectedCusName;
    ////console.log("Acc selected=",accSelected,customerSelected,accountName,customerName)
    addQboConfigs(accSelected, customerSelected,accountName,customerName);
  }

  const handleAccountSelectFormSubmit = (evt) => {
    
    evt.preventDefault();
    // //console.log("accounts=",accounts,accounts[0].label,accSelected)
    ////console.log("customers=",customers)
    var accountName = accounts.length !==0 ? accSelected === null || accSelected === undefined || accSelected === "Not selected" ? accounts[0].label : accounts.find(x => x.value === accSelected).label : selectedAccName;
    var customerName = selectedCusName;
    ////console.log("Acc selected=",accSelected,customerSelected,accountName,customerName)
    addQboConfigs(accSelected, customerSelected,accountName,customerName);
  }

  const accountSelectionModal = (
    <div style={{height: '500px'}}>
      <Modal
        open={activeAccountModal}
        onClose={handleAccountModalChange}
        title="Sales Account"
        primaryAction={{
          content: 'Save',
          onAction: handleAccountSelectFormSubmit,
        }}
      >
        <Modal.Section>
          <TextContainer>
          <Select
                  label = "Select Sales Account"
                  helpText = "Sales account needed to sync sales orders to customers"
                  options={accounts}
                  onChange={e => handleAccSelectChange(e)}
                  value={accSelected}
                />
          </TextContainer>
        </Modal.Section>
      </Modal>
      {loader}
    </div>
  );

  const customerSelectionModal = (
    <div style={{height: '500px'}}>
      <Form onSubmit={handleCustomerSelectFormSubmit}>
      <Modal
        open={activeCustModal}
        onClose={handleCustModalChange}
        title="Default customer"
        primaryAction={{
          content: 'Save',
          onAction: handleCustomerSelectFormSubmit,
        }}
      >
        <Modal.Section>
          <TextContainer>
          <Select
                  label = "Select default customer"
                  helpText = "Default customer needed to assign customer to sales without customer"
                  options={customers}
                  onChange={e => handleCustomerSelectChange(e)}
                  value={customerSelected}
                />

          </TextContainer>
          {loader}
        </Modal.Section>
      </Modal>
      </Form>
    </div>
  );


  return (
    <>
      <Form onSubmit={handleFormSubmit}>
        <Card sectioned>
          <FormLayout>
            <FormLayout.Group>
              <div><Subheading>Select Sales Account</Subheading><br></br>
                <Stack>
                <><Caption >{selectedAccName}</Caption></>
                <><Button plain onClick={() => handleAccountSelectModal()}>change</Button></>
                </Stack>
              </div>
              <div><Subheading>Select Default Customer</Subheading><br></br>
              <Stack>
              <><Caption >{selectedCusName}</Caption></>
                <><Button plain onClick={() => handleCustomerSelectModal()}>change</Button></>
                </Stack>
              </div>
            </FormLayout.Group>
          </FormLayout>
        </Card>
        <PageActions
          primaryAction={{
            content: 'Save',
            submit: true
          }}
        />
      </Form>
      
      {accountSelectionModal}
      {customerSelectionModal}
      {loader}
      {toastMarkup}
      {toastErrorMarkup}
    </>
  );
  
  function handleAccountSelectModal()
  {
    getQBOAccounts();
    setActiveAccountModal(true);
  }

  function handleCustomerSelectModal()
  {
    getCustomers();
    setActiveCustModal(true);
  }
}

