import React, { useEffect, useRef, useState } from 'react';
import './NavMenu.css';
import { Redirect } from 'react-router';
import { environment } from './environments/environment.prod';
import { NavbarItem } from './NavbarItem';
import Api from './Services/CommonServices';
const app_name = environment.appName;
const commonService = new Api();
export function NavMenu() {
  const [shopExist, setShopExist] = useState(null);
  const [frame, setFrame] = useState();
  const [chargeStatus, setChargeStatus] = useState('');
  const shopOrigin = useRef('');
  useEffect(() => {
    shopOrigin.current = commonService.QueryStringToJSON();
    getShopExists();
    setFrame(window.self !== window.top);
    if (shopExist === true) {
      commonService.chargeStatus(shopOrigin.current.shop)
        .then(res => {
          setChargeStatus(res);
        })
    }

  }, []);
  async function getShopExists() {
    commonService.checkIsShopExits(shopOrigin.current.shop)
      .then(res => {
        if (res === true) {
          getChargeStatus();
          setShopExist(true);
        }
        else{
          setShopExist(false);
        }
        
      })
  }
  async function getChargeStatus() {
    commonService.chargeStatus(shopOrigin.current.shop)
      .then(res => { 
        if(res != undefined)
        {setChargeStatus(res);}
      })
  }

  if (shopExist === true && chargeStatus === "Pending") {
    return (commonService.addCharge(shopOrigin.current.shop)
      .then(res => {
        window.open(res, '_top');

      }, err => {
        // console.log(`err = ${JSON.stringify(err)}`);
      }));
  } else if (shopExist === false) {
    if (shopOrigin.current.shop != null) {
      return (commonService.generateAuthUrlCustom(shopOrigin.current.shop)
        .then(res => {
          window.open(res, '_top');
        }, err => {
          //console.log(`err = ${JSON.stringify(err)}`);
        }));
    } else if (window.location.pathname === "/privacy") {
      return (
        <Redirect to='/privacy' />
      )
    }
    else {
      return (
        <Redirect to='/home' />
      )
    }
  }
  else {
    return frame === false && shopExist === true && chargeStatus === "Active" ? (window.open("https://"+shopOrigin.current.shop + "/admin/apps/"+app_name + window.location.pathname,'_top'))
                          : (<NavbarItem/>)   
    // return shopExist === true && chargeStatus === "Active" ? (window.open("https://" + shopOrigin.current.shop + "/admin/apps/" + app_name + window.location.pathname, '_top'))
    //   : (<NavbarItem />)
  }

}
