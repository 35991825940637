import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Card, Page, Tabs } from '@shopify/polaris';
import { Setting } from '../Setting';
import useSuccessToast from '../Toast/UseSuccessToast';
import useErrorToast from '../Toast/UseErrorToast';
import useLoader from '../Loader/UseLoader';
import { JsonConfig } from './JsonConfig';
import { JsonLogs } from './JsonLogs';
import Api from '../Services/CommonServices';
const api = new Api();
export function JsonSetup() {
  const shopOrigin = useRef('');
  const [active, setActive] = useState(true);
  const [goBackActive, setGoBackActive] = useState(false);
  const handleToggle = useCallback(() => setActive((active) => !active), []);
  const contentStatus = active ? 'Disable' : 'Enable';
  const textStatus = active ? 'enabled' : 'disabled';
  const [toastMarkup, showToast] = useSuccessToast();
  const [toastErrorMarkup, showErrorToast] = useErrorToast();
  const [loader, showLoader, hideLoader] = useLoader();
  useEffect(() => {
    shopOrigin.current = api.QueryStringToJSON();
    getJsonDataConfig();
  }, []);
  const handleFormToggle = (evt) => {
    evt.preventDefault();
  }
  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    [],
  );

  function getJsonDataConfig() {
    api.getJsonDataConfig(shopOrigin.current.shop)
        .then(data => {
            console.log("getJsonDataConfig=",data);
            setActive(data.jsonStatus === 0 ? true : false);
        });
}

  const isEnabled = () => {
    getJsonDataConfig();
  }


  const jsonConfig = (
    <>
      <JsonConfig isEnabled={isEnabled} />
    </>
  );

  const logs = (
    <>
      <JsonLogs />
    </>
  );

  const tabs = active ? [
    {
      id: 'json-config',
      content: 'Config',
      componentRender: jsonConfig,
    }
    ,
    {
      id: 'logs',
      content: 'Logs',
      componentRender: logs,
    }] :
    [{
      id: 'json-config',
      content: 'Config',
      componentRender: jsonConfig,
    }];




  return (
    <>
      {goBackActive == false ?
        <Page title='JSON Setup' breadcrumbs={[{ content: 'Settings', onAction: goBack }]}>
          <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
            <Card.Section title={tabs[selected].componentRender}>
            </Card.Section>
          </Tabs>
          {toastMarkup}
          {toastErrorMarkup}
        </Page>
        : <Setting />}
    </>
  );


  function goBack() {
    setGoBackActive(true);
  }
}