import React, { useCallback, useState, useEffect, useRef } from 'react';
import { AppProvider, Card, Form, Layout, Page, SettingToggle, Tabs, TextStyle } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import { Setting } from '../Setting';
import { SalesOrder } from './SalesOrder';
import useSuccessToast from '../Toast/UseSuccessToast';
import useErrorToast from '../Toast/UseErrorToast';
import useLoader from '../Loader/UseLoader';
import { TallyConfig } from './TallyConfig';
import { TallyLogs } from './TallyLogs';
import Api from '../Services/CommonServices';
const api = new Api();
export function TallySetup() {
  const shopOrigin = useRef('');
  const [active, setActive] = useState(false);
  const [goBackActive, setGoBackActive] = useState(false);
  const handleToggle = useCallback(() => setActive((active) => !active), []);
  const contentStatus = active ? 'Disable' : 'Enable';
  const textStatus = active ? 'enabled' : 'disabled';
  const [toastMarkup, showToast] = useSuccessToast();
  const [toastErrorMarkup, showErrorToast] = useErrorToast();
  const [loader, showLoader, hideLoader] = useLoader();
 
  useEffect(() => {
    shopOrigin.current = api.QueryStringToJSON();
  }, []);
  const handleFormToggle = (evt) => {
    evt.preventDefault();
  }
  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    [],
  );

  function getTallyConfig() {
    api.getTallyConfig(shopOrigin.current.shop)
        .then(data => {
            console.log("getTallyConfig=",data);
            setActive(data.tallifyStatus === 0 ? true : false);
        });
}

  const isEnabled = () => {
    getTallyConfig();
  }

  const salesOrder = (
    <Layout>
      <Layout.AnnotatedSection
        title="Sales order"
        description="Click on create invoice to sync the order to quickbooks">
        <Card sectioned>
          <SalesOrder />
        </Card>
      </Layout.AnnotatedSection>
      {loader}
    </Layout>
  );

  const tallyConfig = (
    <>
      <TallyConfig isEnabled={isEnabled} />
    </>
  );

  // const tabs = [
  //   {
  //     id: 'qbo-config',
  //     content: 'Config',
  //     componentRender: tallyConfig,
  //   }
  //   // ,
  //   // {
  //   //   id: 'sales-order',
  //   //   content: 'Sales Order',
  //   //   componentRender: salesOrder,
  //   // }
  // ];

  const logs = (
    <>
      <TallyLogs />
    </>
  );

  const tabs = active ? [
    {
      id: 'tally-config',
      content: 'Config',
      componentRender: tallyConfig,
    }
    ,
    {
      id: 'logs',
      content: 'Logs',
      componentRender: logs,
    }] :
    [{
      id: 'tally-config',
      content: 'Config',
      componentRender: tallyConfig,
    }];



  return (
    <>
      {goBackActive == false ?
        <Page title='Tally Setup' breadcrumbs={[{ content: 'Settings', onAction: goBack }]}>
          <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
            <Card.Section title={tabs[selected].componentRender}>
            </Card.Section>
          </Tabs>
          {toastMarkup}
          {toastErrorMarkup}
        </Page>
        : <Setting />}
    </>
  );


  function goBack() {
    setGoBackActive(true);
  }
}