import React, { useState, useEffect, useCallback } from 'react';
import { AppProvider, FormLayout, TextField, Page, DisplayText, Stack, FooterHelp, Link, Heading, Button, Card, Layout, Form } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import Api from './Services/CommonServices';
import logo from './../assets/tallify-logo.png';
import inventoryImage from './../assets/tallify-inventory-sync.png';
import godownsImage from './../assets/tallify-multiple-godowns.png';
import orderImage from './../assets/tallify-order-sync.png';
// import { environment } from './environments/environment.prod';
const api = new Api();
// let storeUrl = "";

export function VerifyShop() {
  const [shopUrl, setShopUrl] = useState('');
  var queryParams = {};
  const handleChange = useCallback((newValue) => setShopUrl(newValue), []);

  useEffect(() => {
    // localStorage.removeItem("shopUrl");
    getStoreUrl();
  }, []);

  async function generateAuthUrl() {
    api.generateAuthUrl(shopUrl)
      .then(res => {
        //console.log(`res = ${res}`);
        window.open(res, '_top');
      }, err => {
        //console.log(`err = ${JSON.stringify(err)}`);
      });
  }

  // async function checkIsShopExists(){
  //   return new Promise((resolve, reject) => {
  //       api.checkIsShopExits(shopUrl)
  //     .then(res => {
  //       //console.log(`res = ${res}`);
  //       resolve(res);
  //     }, err => {
  //       //console.log(`err = ${JSON.stringify(err)}`);
  //       reject();
  //     });
  //   });
  // }

  const handleFormSubmit = (evt) => {
    evt.preventDefault();
    getStarted()
    //console.log(shopUrl);
  }

  async function getStoreUrl() {
    //console.log(`queryparams = ${JSON.stringify(api.QueryStringToJSON())}`);
    queryParams = api.QueryStringToJSON();

    //console.log("currentUrl", queryParams)
    //  if(queryParams == null)
    //  {
    //    return <Redirect to = "/settings"/>
    //  }
    if (queryParams && queryParams.shop) {
      setShopUrl(queryParams.shop);
      //console.log(`shopUrl = ${shopUrl}`);
    }
  }
  async function getStarted() {
    shopUrl.trim();
    generateAuthUrl();
    // if (shopUrl) {
    //   checkIsShopExists()
    //   .then(res => {
    //     //console.log("WindowOpen",res);
    //     if (res) {

    //       window.open(`https://` + shopUrl + `/admin/apps/${environment.appName}`, '_top');
    //     } else {
    //       generateAuthUrl();
    //     }
    //   })
    //   .catch(err => {
    //   });
    //     generateAuthUrl();
    // // } else {
    // }
  }
  // const config = {apiKey: environment.apiKey, shopOrigin: queryParams.shop};
  // render() {
  //   if(this.state.toDashboard) {
  // return <Redirect to="settings"/>
  // }
  // if(localStorage.getItem('shopUrl')!=null ){
  //   return <Layout1><Route path='/settings' component={SettingsForm} />
  //     <Route path='/logs' component={Logs} />
  //     <Route path='/privacy' component={PrivacyPolicy} /></Layout1>
  // }
  // else {
  //   return <Redirect to="settings"/>
  // }
  return (

    <AppProvider i18n={enTranslations}>
      <Page >

        <Stack>
          <Stack.Item fill>

          </Stack.Item>
          <Stack.Item>
            <div style={{ display: "flex", alignItems: 'center', flexDirection: 'column', marginBottom: '1rem' }} >
              <img src={logo} width={100} />
              <DisplayText size="extraLarge">Tallify</DisplayText>
              <DisplayText size="medium">Sync Tally ERP 9 or Quickbooks Online with Shopify.</DisplayText>
            </div>
          </Stack.Item>
          <Stack.Item fill>

          </Stack.Item>
        </Stack>
        <br />

        <Layout style={{ display: "flex", alignItems: 'center' }} >
          <Layout.Section oneThird>
            <Card sectioned>
              <img src={inventoryImage} style={{ width: "100%" }} />
              <Heading>Inventory Updates</Heading><br />
              <p>Update Shopify inventory quantity or price from Tally ERP 9, Quickbooks Online, or a Custom CSV/JSON every 5 minutes or a custom interval</p>
            </Card>
          </Layout.Section>
          <Layout.Section oneThird>
            <Card sectioned>
              <img src={godownsImage} style={{ width: "100%" }} />
              <Heading>Multi location</Heading><br />
              <p>Sync from multiple godowns in Tally to one location in Shopify. Soon you will be able to sync multiple godowns with multiple Shopify locations.</p>
            </Card>
          </Layout.Section>
          <Layout.Section oneThird>
            <Card sectioned>
              <img src={orderImage} style={{ width: "100%" }} />
              <Heading>Sync Orders/Customers</Heading><br />
              <p>Synchronise Orders and Customers to Quickbooks Online along inventory info your stock items from Quickbooks Online to Shopify.</p>
            </Card>
          </Layout.Section>
        </Layout>
        <br></br>
        <Layout>
          <Layout.Section>
            <div style={{ textAlign: 'center', margin: '2rem auto', alignItems: 'center', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', marginBottom: '1rem' }} >
              <DisplayText size="medium">Enter your store url to get started</DisplayText>
              <br></br>
              <Form onSubmit={handleFormSubmit}>
                <FormLayout>
                  <TextField
                    helpText="Example: awesome-store.myshopify.com"
                    type="text"
                    id="storeName"
                    placeholder='my-amazing-store'
                    value={shopUrl}
                    onChange={handleChange}
                    connectedRight={<Button submit primary>Install</Button>}
                    suffix=".myshopify.com"
                    autoComplete="off"
                  />

                </FormLayout>
              </Form>
            </div>
          </Layout.Section>
        </Layout>
        <FooterHelp>
          Learn more about{' '}
          <Link url="/privacy">
          Privacy
          </Link>
        </FooterHelp>
      </Page>
    </AppProvider>


  );
}

export default VerifyShop
