import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Card, FormLayout, TextField, PageActions, Form, Select, Button, AppProvider, TextStyle, ChoiceList, Subheading, Layout, Page, SettingToggle, RadioButton, Stack, Icon, Banner, SkeletonPage, SkeletonDisplayText, SkeletonBodyText, TextContainer, Modal, List, EmptyState, Checkbox } from '@shopify/polaris';
import useLoader from '../Loader/UseLoader';
import Api from '../Services/CommonServices';
import useSuccessToast from '../Toast/UseSuccessToast';
import useErrorToast from '../Toast/UseErrorToast';
import { environment } from '../environments/environment.prod';
import { KeyMajor, RefreshMinor } from '@shopify/polaris-icons';
import { JsonSetup } from './JsonSetup';
const api = new Api();
const endpoint = environment.serverUrl;
export function JsonConfig(props) {

    // variables
    const { enabled, isEnabled } = props;
    const [loader, showLoader, hideLoader] = useLoader();
    const [toastMarkup, showToast] = useSuccessToast();
    const [toastErrorMarkup, showErrorToast] = useErrorToast()
    const [active, setActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleToggle = useCallback(() => { setActive((active) => !active); }, []);
    const contentStatus = active ? 'Disable' : 'Enable';
    const textStatus = active ? 'enabled' : 'disabled';
    const [apiKey, setApiKey] = useState('');
    const [saveBtnLoad, setSaveBtnLoad] = useState(false);
    const [modalActive, setModalActive] = useState(false);
    const handleModalChange = useCallback(() => { setModalActive((modalActive) => !modalActive) }, []);
    const [priceChecked, setPriceChecked] = useState(false);
    const handlePriceChange = useCallback((newChecked) => { setPriceChecked(newChecked);setIsFormValid(true); }, []);
    const [compareAtPriceChecked, setCompareAtPriceChecked] = useState(false);
    const handleCompareAtPriceChange = useCallback((newChecked) => { setCompareAtPriceChecked(newChecked);setIsFormValid(true); }, []);
    const [stockChecked, setStockChecked] = useState(false);
    const [isFormValid, setIsFormValid] = useState(true);
    const [isLocationSelected, setIsLocationSelected] = useState(true);
    const handleStockChange = useCallback((newChecked) => { setStockChecked(newChecked); setIsFormValid(true); }, []);
    const shopOrigin = useRef('');
    const [selectedLocation, setSelectedLocation] = useState("");
    const [locationCount, setLocationCount] = useState(1);
    const [locations, setLocations] = useState([]);
    const handleSelectChange = useCallback((value) => { setSelectedLocation(value); console.log("selected=",value)}, []);
    // handle state change

    useEffect(() => {
        shopOrigin.current = api.QueryStringToJSON();
        getLocations();
        getStore();
        
    }, []);

    const handleFormSubmit = (evt) => {
        evt.preventDefault();
        addJsonConfigs();
    }


    const handleFormToggle = (evt) => {
        evt.preventDefault();
        jsonConfigStatus();
    }




    // html 

    const disabledBanner = (
        <Layout>
            <Layout.AnnotatedSection>
                <EmptyState
                    heading="Json Import integration is disabled">
                    <p>Enabling Json Import will take you to configuration .</p>
                </EmptyState>
            </Layout.AnnotatedSection>
        </Layout>
    );

    const apiKeyConfirmationModalHtml = (
        <div style={{ height: '500px' }}>
            <Modal
                open={modalActive}
                onClose={handleModalChange}
                title="Are you sure you want to generate new API key ?"
                primaryAction={{
                    destructive: true,
                    content: 'Generate new api key',
                    onAction: GenerateApiKey,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: handleModalChange,
                    },
                ]}>
                <Modal.Section>
                    <TextContainer>
                        <p>
                            This will remove all the connections between client and server.<br />
                            This action cannot be reversed.
                        </p>
                    </TextContainer>
                </Modal.Section>
            </Modal>
        </div>
    );

    return (
        <>
            <div className="spinner_overlay">{loader}</div>
            <Layout>
                <Layout.AnnotatedSection>
                    <Card>
                        <Form onSubmit={handleFormToggle}>
                            <SettingToggle
                                action={{
                                    content: contentStatus,
                                    onAction: handleToggle,
                                    submit: true
                                }}
                                enabled={active}>
                                Tallify is <TextStyle variation="strong">{textStatus}</TextStyle>.
                            </SettingToggle>
                        </Form>
                    </Card>
                    <Card></Card>
                </Layout.AnnotatedSection>
            </Layout>
            <Form onSubmit={handleFormSubmit}>
                {active ?
                    <>
                        <Layout>
                            <Layout.AnnotatedSection
                                title="Json Config"
                                description="Customize your app settings.">
                                <Card sectioned>
                                    <Stack alignment="leading">
                                        <Stack.Item fill>
                                            <TextField helpText="Use this API key to setup the Json import" label="API key" type="text" value={apiKey} readOnly={true} />
                                        </Stack.Item>
                                        <div style={{ paddingTop: "23px" }}>
                                            <Button primary onClick={handleModalChange} icon={RefreshMinor} accessibilityLabel="Refresh" />
                                        </div>
                                    </Stack><br/>
                                    <TextStyle>Select the fields to sync</TextStyle>
                                    <FormLayout>
                                    <Checkbox
                                        label="Price"
                                        checked={priceChecked}
                                        onChange={handlePriceChange}
                                    />
                                    <Checkbox
                                        label="Compare at price"
                                        checked={compareAtPriceChecked}
                                        onChange={handleCompareAtPriceChange}
                                    />
                                    <Checkbox
                                        label="Stock"
                                        checked={stockChecked}
                                        onChange={handleStockChange}
                                    />
                                    {!isFormValid ? 
                                    <TextStyle variation="negative">Select at least one option</TextStyle> : undefined }
                                    {locationCount > 1 ?
                                        <Select
                                            label="Location"
                                            options={locations}
                                            onChange={handleSelectChange}
                                            helpText="Select location to sync inventory"
                                            value={selectedLocation}
                                        /> : undefined }
                                    </FormLayout>
                                </Card>
                                <PageActions
                        primaryAction={{
                            content: 'Save',
                            submit: true,
                            loading: saveBtnLoad,
                            disabled: !isFormValid
                        }} />
                        {toastMarkup} {toastErrorMarkup}
                                {apiKeyConfirmationModalHtml}
                            </Layout.AnnotatedSection></Layout>
                            <Layout>
            </Layout>
                    </> : disabledBanner}
            </Form>
        </>
    );


    //// functions ////

    function getStore() {
        showLoader();
        api.getStore(shopOrigin.current.shop)
            .then(data => {
                setApiKey(data.jsonApiKey);
                
                getJsonDataConfig();
                
            });
    }

    function getLocations() {
        var locationList= [];
        api.getLocations(shopOrigin.current.shop)
            .then(data => {
                setLocationCount(data.length);
                console.log("locations=",data.length,data);
                data.forEach(locationRes => {
                    var location = {
                        value: locationRes.admin_graphql_api_id,
                        label: locationRes.name
                    }
                    locationList.push(location);
                });
                setLocations(locationList);
                console.log("location list=",locationList);
            });
    }

    function getJsonDataConfig() {
        showLoader();
        api.getJsonDataConfig(shopOrigin.current.shop)
            .then(data => {
                console.log("getJsonDataConfig=",data);
                setActive(data.jsonStatus === 0 ? true : false);
                setPriceChecked(data.priceSync);
                setStockChecked(data.stockSync);
                setCompareAtPriceChecked(data.compareAtPriceSync);
                setSelectedLocation(""+data.locationid);
                hideLoader();
            });
    }

    function addJsonConfigs() {
        setSaveBtnLoad(true);
        var data = {
            priceSync: priceChecked,
            stockSync: stockChecked,
            compareAtPriceSync: compareAtPriceChecked,
            locationId: selectedLocation == '' ? locations[0].admin_graphql_api_id : selectedLocation
        }
        if (priceChecked || stockChecked || compareAtPriceChecked) {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data)
                };
                fetch(endpoint + '/api/TallyConfig/add-json-config?shopUrl=' + shopOrigin.current.shop, requestOptions)
                    .then(function (response) {
                        if (response.status === 200 && response != null) {
                            showToast();
                            getJsonDataConfig();
                            isEnabled();
                            setSaveBtnLoad(false);
                            return response
                        } else {
                            showErrorToast();
                            setSaveBtnLoad(false);
                            throw new Error('Empty data')
                        }
                    })
                    .catch(function (error) {
                        setSaveBtnLoad(false);
                        return []
                    })
        }
        else { setIsFormValid(false); setSaveBtnLoad(false); }
    }


    function jsonConfigStatus() {
        showLoader();
        setSaveBtnLoad(true);
        var status = active ? "Enabled" : "Disabled"
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch(endpoint + '/api/TallyConfig/json-config-status?shopUrl=' + shopOrigin.current.shop + "&status=" + status, requestOptions)
            .then(function (response) {
                if (response.status === 200 && response != null) {
                    showToast();
                    hideLoader();
                    getStore();
                    isEnabled();
                    setSaveBtnLoad(false);
                    return response
                } else {
                    showErrorToast();
                    hideLoader();
                    setSaveBtnLoad(false);
                    throw new Error('Empty data')
                }
            })
            .catch(function (error) {
                hideLoader();
                setSaveBtnLoad(false);
                return []
            })
    }

    function GenerateApiKey() {
        showLoader();
        api.generateAPIKey(shopOrigin.current.shop).then(response => {
            if (response) {
                api.getStore(shopOrigin.current.shop)
                    .then(data => {
                        setApiKey(data.tallyApiKey);
                        setModalActive(false);
                        showToast()
                    });
            }
            else { showErrorToast() }
            hideLoader();
        })
    }
}
