
import Api from '../Services/CommonServices';
const commonService = new Api();

var shopOrigin ={};
  
export class requireAuth {

  canActivate() 
  {
    // storage.local.removeItem('shopUrl');
    shopOrigin = commonService.QueryStringToJSON();

    if(shopOrigin.shop !== undefined)
    {
      // storage.local.setItem("shopUrl", shopOrigin.shop);
      commonService.checkIsShopExits(shopOrigin.shop).then(res => {
        console.log("shopi exist=",res);
      if(!res)
      {
        commonService.generateAuthUrl(shopOrigin.shop)
        .then(authUrl => {
          //console.log("shopi authUrl=",authUrl);
          window.open(authUrl, '_top');
        })
      }
    })
    }


    return new Promise((resolve, reject) => 
    {
      if (commonService.isHmacVerified) 
      {
        if (!(commonService.recurringChargeStatus === 'Active')) 
        {
          commonService.createRecurringCharge(commonService.QueryStringToJSON().shop)
          .then(recurringCharge => {
          window.open(recurringCharge, '_top');
          }, err => {
          // //console.log(`error = > ${JSON.stringify((err))}`);
          });
        } 
        else 
        {
        resolve(true);
        }
      }
    });
  }


  verifyHmac(queryString) {
  return new Promise((resolve, reject) => {
  commonService.verifyShop(queryString)
  .then((res) => {
  commonService.isHmacVerified = res.isVerifiedRequest;
  commonService.recurringChargeStatus = res.recurringChargeStatus;
  resolve(res);
  }, err => {
  reject(err.error);
  });
  });
  }

  }


