import React, { useState } from 'react';
import Loader from '.';

const useLoader =() => {
    const [loading, setLoading] = useState(false);
    const showSpinner = () => setLoading(true);
    const hideSpinner = () => setLoading(false);
    const spinner = loading ? <Loader/> : null;
    return[spinner,showSpinner,hideSpinner];
}
export default useLoader;