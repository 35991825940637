import React, { Component } from 'react';
import { NavMenu } from './NavMenu';

export class Layout1 extends Component {
  static displayName = Layout1.name;

  render () {
    
    return (
      
      <div>
        <NavMenu/>
          {this.props.children}
      </div>
    );
    
  }
}
