import React, { useState, useEffect, useRef } from 'react';
import { ResourceItem, ResourceList, TextStyle, Pagination, AppProvider } from '@shopify/polaris';
import useLoader from '../Loader/UseLoader';
import useSuccessToast from '../Toast/UseSuccessToast';
import useErrorToast from '../Toast/UseErrorToast';
import Api from '../Services/CommonServices';
import { environment } from '../environments/environment.prod';
const endpoint = environment.serverUrl;
const api = new Api();
export function SalesOrder() {
  const [toastMarkup, showToast] = useSuccessToast();
  const [toastErrorMarkup, showErrorToast] = useErrorToast();
  const [value, setValue] = useState([]);
  const [logs, setLogs] = useState([]);
  const [loader, showLoader, hideLoader] = useLoader();
  const [nxtButton, setNxtButton] = useState(true);
  const [prvButton, setPrvButton] = useState(true);
  const resourceName = {
    singular: 'order',
    plural: 'orders'
  };
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setPrevPage] = useState(false);
  const [afterCursor, setAfterCursor] = useState(" ");
  const [prevCursor, setPrevCursor] = useState(" ");
  const shopOrigin = useRef('');
  useEffect(() => {
    shopOrigin.current = api.QueryStringToJSON();
    //console.log("shopOrigin=", shopOrigin);
    getGraphqlNextvOrders();
    getSalesInvoiceLogs();
  }, []);


  function getSalesInvoiceLogs() {
    showLoader();
    api.getTallyInvoiceLogs(shopOrigin.current.shop).then(response => {
      //console.log("invoice logs=",response.data)
      setLogs(response.data);
      hideLoader();
    })
  }

  function getGraphqlNextvOrders() {
    showLoader();
    api.getGraphqlOrders(shopOrigin.current.shop, afterCursor.cursor).then(response => {
      setValue(response.orders.edges)
      setAfterCursor(response.orders.edges[response.orders.edges.length - 1])
      setPrevCursor(response.orders.edges[0])
      setHasNextPage(response.orders.pageInfo.hasNextPage);
      setPrevPage(response.orders.pageInfo.hasPreviousPage);
      hideLoader();
    })
  }
  function getGraphqlPrevOrders() {
    showLoader();
    api.getGraphqlPrevOrders(shopOrigin.current.shop, prevCursor.cursor).then(response => {
      setValue(response.orders.edges)
      setAfterCursor(response.orders.edges[response.orders.edges.length - 1])
      setPrevCursor(response.orders.edges[0])
      setHasNextPage(response.orders.pageInfo.hasNextPage);
      setPrevPage(response.orders.pageInfo.hasPreviousPage);
      hideLoader();
    })

  }

  // function getInvoiceLogs() {
  //   showLoader();
  //   api.getTallyInvoiceLogs(shopOrigin.current.shop).then(response => {
  //     //console.log("invoice logs=",response.data)
  //     hideLoader();
  //   })
  // }

  function handleClick(id, legacyResourceId) {
    createSalesInvoice(id,legacyResourceId);
  }

  async function createSalesInvoice(id, legacyResourceId) {
    const data = {
      store: shopOrigin.current.shop,
      shopifyOrderId: id,
      legacyResourceId: legacyResourceId
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    showLoader();
    fetch(endpoint + '/api/TallifyAppConsole/tally-voucher', requestOptions)
      .then(function (response) {
        if (response.status === 200 && response != null) {
          hideLoader();
          showToast();
          return response
        } else {
          hideLoader();
          showErrorToast();
          throw new Error('Empty data')
        }
      })
      .catch(function (error) {
        hideLoader();
        //console.log(error)
        return []
      })

  }

  function next() {
    if (hasNextPage === true) { getGraphqlNextvOrders(); setPrvButton(true); }
    else { setNxtButton(false); setPrvButton(true); }
  }

  function prev() {
    if (hasPrevPage === true) { getGraphqlPrevOrders(); setNxtButton(true) }
    else { setPrvButton(false); setNxtButton(true) }
  }
  return (
    <AppProvider>
      {loader}
      <ResourceList
        resourceName={resourceName}
        items={value}
        renderItem={renderItem} />
      <Pagination
        hasPrevious={prvButton}
        onPrevious={() => {
          prev();
        }}
        hasNext={nxtButton}
        onNext={() => {
          next();
        }} />
      {toastMarkup}
      {toastErrorMarkup}
    </AppProvider>
  );

  function searchForSelectedItem(orderId) {
    
    return logs.find(item => {
      return item.shopifyOrderId === orderId
    })
  };
  
  function renderItem(item) {
    const { node } = item;
    var result = searchForSelectedItem(node.id)
    let date = new Date(Date.parse(node.createdAt.toLocaleString()));
    var error = result != undefined ? result.error : null
    var status = result != undefined ? result.status : null
    //console.log("result filter=",result);
    var tallyVoucherId = result != undefined ? result.tallyVoucherId : null
    var existingOrderId = result != undefined ? result.shopifyOrderId : null
    const shortcutActions = item
      ? [{ content: 'create invoice', onClick: handleClick.bind(this, node.id, node.legacyResourceId) }]
      : null;
    return (
      <ResourceItem key={node.name} accessibilityLabel={`View details for ${node.name}`} shortcutActions={existingOrderId !== undefined ? shortcutActions : ''}>
        <div>
          <TextStyle variation="strong">{node.name}</TextStyle> placed on
      <TextStyle variation="strong"> {date.toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })}</TextStyle> by
      <TextStyle variation="strong"> {node.customer == null ? "no-customer" : node.customer.displayName}</TextStyle></div>
        <div>
          {status == "Created" ?
          <TextStyle variation="positive">{existingOrderId !== undefined ? "Tally Voucher No: " + tallyVoucherId : ''}</TextStyle> :
           <TextStyle variation="negative">{existingOrderId !== undefined && error !== null ? "error: "+error : ''}</TextStyle>
           }
          
        </div>
      </ResourceItem>
    );
  }
}

